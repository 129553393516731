import { InjectedConnector } from "@web3-react/injected-connector";
import { NetworkConnector } from "@web3-react/network-connector";

const RPC_URLS: { [chainId: number]: string } = {
  56: process.env.RPC_URL_56 as string,
  97: process.env.RPC_URL_97 as string,
};
export const injected = new InjectedConnector({ supportedChainIds: [56] });

export const network = new NetworkConnector({
  urls: { 56: RPC_URLS[56] },
  defaultChainId: 56, //TODO change this
});
