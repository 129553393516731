import { observer } from "mobx-react";
import "./profileComponents.sass";
import styled from "styled-components";
import { SearchInput } from "../../shared/forms/forms";
import { Chevron, Filter } from "../../shared/buttons/buttons";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { debounce } from "lodash";
import {
  DropdownMenuFilter,
  DropdownP,
} from "../../shared/buttons/dropdownMenu";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { NFTstore } from "../../stores/NFTstore.";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 144px 144px;
  column-gap: 30px;
  justify-items: center;
  margin-bottom: 40px;

  & input {
    width: 100%;
  }
  @media (max-width: 500px) {
    column-gap: 3px;
    grid-template-columns: 120px 120px 120px;
  }
  @media (max-width: 420px) {
    column-gap: 0px;
    grid-template-rows: 40px;
    grid-template-columns: 1fr 120px 120px;
    row-gap: 10px;
  }
`;
interface IProfileFilter {
  created?: boolean;
  owned?: boolean;
  selling?: boolean;
  user_id: string;
}
const SearchBar = observer(
  ({ created, owned, selling, user_id }: IProfileFilter) => {
    const userStore = useInjection(UserStore);
    const nftStore = useInjection(NFTstore);
    const [sortDropdown, setSortDropdown] = useState(false);
    const [filterDropdown, setFilterDropdown] = useState(false);
    const { i18n, t } = useTranslation();

    useEffect(() => {
      nftStore.getAllTags();
    }, []);

    const filtersTags = {
      field: "tags",
      filters: nftStore.tags,
    };

    const onSearch = (e) => {
      e.preventDefault();
      userStore.profileSearchFilter(e.target.value, user_id).then((res) => {
        if (created) {
          userStore.getMintedNFT(user_id);
        }
        if (owned || selling) {
          userStore.getOwnedNFT(user_id);
        }
      });
    };
    const debSearch = debounce(onSearch, 500);

    const onFilter = (tag) => {
      userStore.toggleFilter([filtersTags.field, tag]).then((res) => {
        if (created) {
          userStore.getMintedNFT(user_id);
        }
        if (owned || selling) {
          userStore.getOwnedNFT(user_id);
        }
      });
    };
    const onSort = (field, way) => {
      userStore.toggleSort(field, way).then((res) => {
        if (created) {
          console.log(1);
          userStore.getMintedNFT(user_id);
        }
        if (owned || selling) {
          console.log(2);
          userStore.getOwnedNFT(user_id);
        }
      });
    };

    return (
      <Container>
        <SearchInput onChange={(e) => debSearch(e)} border />
        {/* {created&&<div/>} */}
        <Filter
          id="mobile"
          style={{ flexWrap: "nowrap", zIndex: 9999 }}
          onMouseEnter={() => setFilterDropdown(true)}
          onMouseLeave={() => setFilterDropdown(false)}
        >
          {t("button::category")}
          <Chevron src={require("../../images/chevron.png")} />
          {filterDropdown && (
            <DropdownMenuFilter
              style={{ right: "0" }}
              className="border-grarient absolute"
            >
              <DropdownP
                style={{
                  backgroundColor: !userStore.filters.some(
                    (item) => item[0] === "tags"
                  )
                    ? "#e8e8f3"
                    : "",
                }}
                onClick={() => onFilter(false)}
              >
                {t("button::all")}
              </DropdownP>
              {filtersTags.filters?.map((el, i) => {
                return (
                  <DropdownP
                    style={{
                      backgroundColor: userStore.filters.some(
                        (item) => item[1] === el.tag
                      )
                        ? "#e8e8f3"
                        : "",
                    }}
                    key={"filter" + i}
                    onClick={() => onFilter(el.tag)}
                  >
                    {el.languages
                      ? el.languages[i18n.language] &&
                        el.languages[i18n.language]
                      : el.tag}
                  </DropdownP>
                );
              })}
            </DropdownMenuFilter>
          )}
        </Filter>
        {!created && (
          <Filter
            style={{ display: "flex", flexWrap: "nowrap", zIndex: 9998 }}
            onMouseEnter={() => setSortDropdown(true)}
            onMouseLeave={() => setSortDropdown(false)}
          >
            {t("button::sort")}
            <Chevron src={require("../../images/chevron.png")} />
            {sortDropdown && (
              <DropdownMenuFilter
                style={{ right: "0" }}
                className="border-grarient absolute"
              >
                <DropdownP
                  style={{
                    backgroundColor: userStore.nowSorting?.some(
                      (item) => item === "date"
                    )
                      ? "#e8e8f3"
                      : "",
                  }}
                  onClick={() => onSort("date", "desc")}
                >
                  {t("button::sortNew")}
                </DropdownP>
                <DropdownP
                  style={{
                    backgroundColor:
                      userStore.nowSorting?.some((item) => item === "price") &&
                      userStore.nowSorting?.some((item) => item === "desc")
                        ? "#e8e8f3"
                        : "",
                  }}
                  onClick={() => onSort("price", "desc")}
                >
                  {t("button::sortPriceDown")}
                </DropdownP>
                <DropdownP
                  style={{
                    backgroundColor:
                      userStore.nowSorting?.some((item) => item === "price") &&
                      userStore.nowSorting?.some((item) => item === "asc")
                        ? "#e8e8f3"
                        : "",
                  }}
                  onClick={() => onSort("price", "asc")}
                >
                  {t("button::sortPriceUp")}
                </DropdownP>
                <DropdownP
                  style={{
                    backgroundColor: userStore.nowSorting?.some(
                      (item) => item === "popular"
                    )
                      ? "#e8e8f3"
                      : "",
                  }}
                  onClick={() => onSort("popular", "desc")}
                >
                  {t("button::sortPopular")}
                </DropdownP>
                <DropdownP
                  style={{
                    backgroundColor: userStore.nowSorting?.some(
                      (item) => item === "dateSold"
                    )
                      ? "#e8e8f3"
                      : "",
                  }}
                  onClick={() => onSort("dateSold", "desc")}
                >
                  {t("button::sortLastSold")}
                </DropdownP>
              </DropdownMenuFilter>
            )}
          </Filter>
        )}
      </Container>
    );
  }
);

export default SearchBar;
