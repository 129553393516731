import { observer } from "mobx-react";
import "./profileComponents.sass";
import CatalogItem from "../../pages/Catalog/catalogItem";
import { Table } from "../../shared/tables/tables";
import styled from "styled-components";
import SearchBar from "./searchBar";
import { ButtonLight, ButtonText } from "../../shared/buttons/buttons";
import { useEffect, useState } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { web3 } from "../../constants";
import { NFTstore } from "../../stores/NFTstore.";
import { ModalForm } from "../../shared/forms/forms";
import { ModalsEnum, ModalStore } from "../../stores/ModalStore";
import { useTranslation } from "react-i18next";
import { url } from "../auth/helper";
import { toast } from "react-toastify";

const SwitchMenu = styled.div`
  & button {
    font-family: Releway-semiBold;
    font-size: 18px;
    margin-right: 18px;
    border-bottom: 2px solid transparent;
    &:hover {
      border-color: #894ada;
    }
  }
`;
export const Name = styled.p`
  font-family: Releway-semiBold;
  font-size: 20px;
  margin-bottom: 0;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
export const Desc = styled.p`
  font-family: Releway-semiBold;
  font-size: 20px;
  margin-bottom: 0;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
export const Date = styled.p`
  margin-bottom: 0;
`;
export const Price = styled.p`
  margin: 0;
  color: black;
  font-family: Releway-semiBold;
  font-size: 22px;
  line-height: 1em;
  text-align: right;
`;
const Сurrency = styled.p`
  margin: 0;
  color: #959ea7;
  font-family: Releway-medium;
  font-size: 14px;
  text-align: right;
`;

const Selling = observer(() => {
  console.log("saling");

  const { t } = useTranslation();
  const [ammount, setAmmount] = useState(4);
  const [swithMenuState, setSwithMenuState] = useState(99);
  const [sellModal, setSellModal] = useState(false);
  const userStore = useInjection(UserStore);
  const modalStore = useInjection(ModalStore);

  useEffect(() => {
    if (swithMenuState === 0) {
      console.log(userStore.user?._id);
      userStore.getOwnedNFT(userStore.user?._id, false, false);
    } else if (swithMenuState === 1) {
      userStore.getOwnedNFT(userStore.user?._id, true, false);
    } else if (swithMenuState === 2) {
      userStore.getOwnedNFT(userStore.user?._id, true, true);
    }
  }, [swithMenuState]);
  useEffect(() => {
    if (userStore.user) {
      console.log(userStore.user?._id);
      userStore.getOwnedNFT(userStore.user?._id, false, false);
      if (swithMenuState !== 1 && swithMenuState !== 2) {
        setSwithMenuState(0);
      }
    }
  }, [userStore.user]);
  // console.log('selling owned',userStore.ownedNFT)

  return (
    <div className="lk-myNft">
      <SearchBar user_id={userStore.user?._id} selling />
      <SwitchMenu>
        <ButtonText
          onClick={() => setSwithMenuState(0)}
          style={{ borderColor: swithMenuState === 0 ? "#894ADA" : "" }}
        >
          {t("pSelling::inactiveNFT")}
        </ButtonText>
        <ButtonText
          onClick={() => setSwithMenuState(1)}
          style={{ borderColor: swithMenuState === 1 ? "#894ADA" : "" }}
        >
          {t("pSelling::onSale")}
        </ButtonText>
        <ButtonText
          onClick={() => setSwithMenuState(2)}
          style={{ borderColor: swithMenuState === 2 ? "#894ADA" : "" }}
        >
          {t("pSelling::onAuc")}
        </ButtonText>
      </SwitchMenu>
      {swithMenuState === 0 && (
        <Table>
          <tbody>
            {userStore?.ownedNFT?.length > 0 && userStore.ownedNFT ? (
              userStore.ownedNFT
                ?.filter((el) => !el?.isSelling)
                ?.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ width: "1px", padding: "0 10px 0 0" }}>
                        <img src={url + "/" + el?.preview} />
                      </td>
                      <td>
                        <Name>{el?.title}</Name>
                      </td>
                      <td>
                        {console.log(el)}
                        <ButtonText
                          color="#894ADA"
                          onClick={() =>
                            modalStore.showModal(ModalsEnum.SellToken, {
                              id: el?._id,
                              amount:
                                el?.instances?.filter(
                                  (el) =>
                                    !el?.isSelling &&
                                    el?.owner === userStore.user?._id
                                )?.length > 1
                                  ? el?.instances?.filter(
                                      (el) =>
                                        !el?.isSelling &&
                                        el?.owner === userStore.user?._id
                                    ).length
                                  : 1,
                            })
                          }
                        >
                          {t("pSelling::putOnSale")}
                        </ButtonText>
                      </td>
                      <td>
                        <ButtonText
                          color="#894ADA"
                          onClick={() =>
                            el.instances?.length <= 1 || !el.instances
                              ? modalStore.showModal(ModalsEnum.AucToken, {
                                  id: el._id,
                                })
                              : toast.error(t("pSelling::noAuc"))
                          }
                        >
                          {t("pSelling::putOnAuc")}
                        </ButtonText>
                      </td>
                      <td>
                        <Date>{el?.date && el?.date?.slice(0, 10)}</Date>
                      </td>
                      <td>
                        <Price>
                          {el?.price
                            ? web3.utils.fromWei(el?.price?.toString(), "ether")
                            : 0}{" "}
                          BNB
                        </Price>
                        <Сurrency>
                          ≈ ₽{" "}
                          {userStore?.bnb && el?.price
                            ? (
                                userStore?.bnb *
                                parseFloat(
                                  web3?.utils?.fromWei(
                                    el?.price?.toString(),
                                    "ether"
                                  )
                                )
                              )?.toFixed(2)
                            : 0}
                        </Сurrency>
                      </td>
                    </tr>
                  );
                  // }
                })
            ) : (
              <tr>
                <td style={{ textAlign: "left", paddingLeft: "8px" }}>
                  Нет неактивных NFT
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}

      {swithMenuState === 1 && (
        <Table>
          <tbody>
            {userStore?.ownedNFT?.length > 0 && userStore.ownedNFT ? (
              userStore.ownedNFT
                ?.filter((el) => el?.isSelling)
                ?.filter((el) => !el?.auction?.open)
                ?.map((el, i) => {
                  // if(i<ammount){
                  //
                  {
                    console.log(el);
                  }
                  return (
                    <tr key={i}>
                      <td style={{ width: "1px", padding: "0 10px 0 0" }}>
                        <img src={url + "/" + el?.preview} />
                      </td>
                      <td>
                        <Name>{el?.title}</Name>
                      </td>
                      <td>
                        <ButtonText
                          color="#894ADA"
                          onClick={() =>
                            el?.instances?.filter(
                              (el) =>
                                el?.isSelling &&
                                el?.owner === userStore.user?._id
                            )?.length > 1
                              ? modalStore.showModal(ModalsEnum.WithdrawToken, {
                                  id: el?._id,
                                  amount:
                                    el?.instances?.filter(
                                      (el) =>
                                        el?.isSelling &&
                                        el?.owner === userStore.user?._id
                                    )?.length > 1
                                      ? el?.instances?.filter(
                                          (el) =>
                                            el.isSelling &&
                                            el.owner === userStore.user?._id
                                        ).length
                                      : 1,
                                })
                              : userStore.stopSelling(el._id, { amount: 1 })
                          }
                        >
                          {t("pSelling::putOfSelling")}
                        </ButtonText>
                      </td>

                      <td>
                        <Date>{el?.date && el?.date?.slice(0, 10)}</Date>
                      </td>
                      <td>
                        <Price>
                          {el?.price &&
                            web3.utils.fromWei(
                              el?.price?.toString(),
                              "ether"
                            )}{" "}
                          BNB
                        </Price>
                        <Сurrency>
                          ≈
                          {userStore.bnb &&
                            el?.price &&
                            (
                              userStore.bnb *
                              parseFloat(
                                web3.utils.fromWei(
                                  el?.price?.toString(),
                                  "ether"
                                )
                              )
                            ).toFixed(2)}
                        </Сurrency>
                      </td>
                    </tr>
                  );
                  // }
                })
            ) : (
              <tr>
                <td style={{ textAlign: "left", paddingLeft: "8px" }}>
                  Нет NFT на продаже
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}

      {swithMenuState === 2 && (
        <Table>
          <tbody>
            {userStore?.ownedNFT?.length > 0 && userStore.ownedNFT ? (
              userStore.ownedNFT
                ?.filter((el) => el?.isSelling)
                ?.filter((el) => el?.auction?.open)
                ?.map((el, i) => {
                  // if(i<ammount){
                  // console.log('ellllllll',el.price, el.auction)
                  return (
                    <tr key={i}>
                      <td style={{ width: "1px", padding: "0 10px 0 0" }}>
                        <img src={url + "/" + el?.preview} />
                      </td>
                      <td>
                        <Name>{el?.title}</Name>
                      </td>
                      <td>
                        <ButtonText
                          color="#894ADA"
                          onClick={() =>
                            userStore.stopSelling(el._id, { amount: 1 })
                          }
                        >
                          {t("pSelling::putOfAuc")}
                        </ButtonText>
                      </td>
                      <td>
                        <Date>{el?.date && el?.date?.slice(0, 10)}</Date>
                      </td>
                      <td>
                        <Price>
                          {web3.utils.fromWei(el?.price?.toString(), "ether")}{" "}
                          BNB
                        </Price>
                        <Сurrency>
                          {userStore.bnb &&
                            (
                              userStore.bnb *
                              parseFloat(
                                web3.utils.fromWei(
                                  el?.price?.toString(),
                                  "ether"
                                )
                              )
                            ).toFixed(2)}
                        </Сurrency>
                      </td>
                    </tr>
                  );
                  // }
                })
            ) : (
              <tr>
                <td style={{ textAlign: "left", paddingLeft: "8px" }}>
                  Нет выставленных на аукцион NFT
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}

      {/* {<div>
                <ModalForm button='Отправить' title='Отправить на продажу' onSubmit={sendToSell} >
                    
                </ModalForm>   
            </div>} */}
      {/* {ammount<user?.minted_tokens?.length && <div style={{display:'flex', justifyContent:'center', margin: '20px 0 0'}}>
                <ButtonLight  width='200px' onClick={()=>setAmmount(ammount+4)}>Показать еще</ButtonLight>
            </div>} */}
    </div>
  );
});

export default Selling;
