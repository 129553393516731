import React from "react";
import Modal from "../components/Modal";
import { ModalsEnum, ModalStore } from "../stores/ModalStore";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import {
  SubmitButton,
  TextAreaInput,
  TextInput,
  Title,
} from "../shared/forms/forms";
import { UserStore } from "../stores/user/UserStore";
import { toast } from "react-toastify";
import { NFTstore } from "../stores/NFTstore.";
import Web3 from "web3";
import { WithTranslation, withTranslation } from "react-i18next";
interface IConnectModalProps extends WithTranslation {
  data: { id: string; title: string };
  idx: number;
}

interface IConnectModalState {}

@observer
class СollectionDelete extends React.Component<
  IConnectModalProps,
  IConnectModalState
> {
  @resolve(ModalStore)
  protected declare readonly modalStore: ModalStore;
  @resolve(UserStore)
  protected declare readonly userStore: UserStore;
  @resolve(NFTstore)
  protected declare readonly nftStore: NFTstore;

  onSubmit = () => {
    this.userStore
      .deleteCollection(
        this.props.data.id,
        this.props.t("collections::deleted")
      )
      .then((res) => {
        // toast.success(this.props.t('collections::deleted'))

        this.modalStore.hideModal(this.props.idx);
        this.userStore.getCollections("self");
      })
      .catch((err) => {
        console.log(err);
        let msg = err.response.data.err;
        err?.message && toast.error(msg);
      });
  };
  onCancel = () => {
    this.modalStore.hideModal(this.props.idx);
  };
  render() {
    console.log(this.props.data);
    const { t } = this.props;
    return (
      <Modal modalKey={ModalsEnum.Collections} idx={this.props.idx}>
        <Title>
          {t("collections::yousure")}&ensp;{this.props.data.title} ?
        </Title>
        <SubmitButton className="border-grarient" onClick={this.onSubmit}>
          {t("collections::delete")}{" "}
        </SubmitButton>
        <SubmitButton
          className="border-grarient"
          type="button"
          style={{ backgroundColor: "grey", color: "white" }}
          onClick={this.onCancel}
        >
          {t("collections::cancel")}{" "}
        </SubmitButton>
      </Modal>
    );
  }
}
export default withTranslation()(СollectionDelete);
