import "./index.sass";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useInjection } from "inversify-react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { NFTstore } from "./../../stores/NFTstore.";
import { UserStore } from "./../../stores//user/UserStore";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";
import { ButtonLight } from "../../shared/buttons/buttons";
import CatalogItem from "../Catalog/catalogItem";
import FAQ from "./../../components/faq/FAQ";
import Credits from "./../../components/credits";

const LastNft = styled.div`
  margin-top: 80px;
  padding: 60px 35px;
  & h1 {
    text-align: center;
    margin-bottom: 80px;
  }
  & .nft-grid {
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 20px;
  }
  @media (max-width: 1300px) {
    & .nft-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 1000px) {
    & .nft-grid {
      grid-template-columns: 1fr;
    }
  }
`;

const ToureSection = styled.div`
  padding-top: 80px;
`;

const ToureWrapper = styled.div`
  width: 100%;
  height: 371px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (max-width: 1255px) {
    margin-top: 60px;
  }
`;

const Toure = styled.div`
  position: relative;
  z-index: 2;
  max-width: 683px;
  width: 683px;
  height: calc(100% - 15px);
  display: flex;
  justify-content: center;
  align-items: flex-end;

  background-image: url(${(p: { bgPath: string }) => p.bgPath});
  background-size: cover;
  background-position: top center;
`;

const CatalogButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  position: relative;
  z-index: 2;
`;

const Home = observer(() => {
  const nftStore = useInjection(NFTstore);
  const userStore = useInjection(UserStore);

  const { t } = useTranslation();

  useEffect(() => {
    nftStore.getMostExpensiveNFT();
    nftStore.getOfferNFT();
    nftStore.getNFTcatalog().then((res) => console.log(res, "res in comp"));
  }, []);

  return (
    <SmoothAppearance trigger>
      <div
        className="main-container"
        id="mainc"
        // style={{
        //   backgroundImage: `url(${require("./../../images/landing-start.jpg")})`,
        //   backgroundSize: "cover",
        // }}
      >
        <div className="news-banner">
          <div>
            <div className="news-text">{t("news::header")}</div>

            <div className="news-buttons">
              {!userStore.isAuth && (
                <Link to="/p/auth">
                  <ButtonLight
                    className="custom-bg-gradient"
                    width="190px"
                    style={{ marginRight: "12px" }}
                  >
                    {t("button::authorization")}
                  </ButtonLight>
                </Link>
              )}
              <Link to="/p/catalog">
                <ButtonLight
                  width="190px"
                  className={
                    userStore.isAuth ? "custom-bg-gradient " : "border-grarient"
                  }
                >
                  {t("button::catalog")}
                </ButtonLight>
              </Link>
            </div>
          </div>
          <div className="news-banner-nft">
            {nftStore.offerNFT && (
              <CatalogItem
                el={nftStore.offerNFT}
                mostExpensive={true}
              ></CatalogItem>
            )}
          </div>
        </div>
      </div>
      <ToureSection>
        <div className="main-container">
          <ToureWrapper className="border-grarient">
            <Toure bgPath={require("./../../images/toure-bg.png")}>
              <ButtonLight
                style={{ marginBottom: "26px" }}
                className="custom-bg-gradient"
                width="196px"
                onClick={() => {
                  window.location.assign("https://mechanicsofwonder.com");
                }}
              >
                {t("navbar::vrTour")}
              </ButtonLight>
            </Toure>
          </ToureWrapper>
        </div>
      </ToureSection>

      <LastNft className="main-container border-grarient">
        <h1 style={{ color: "#fff" }}>{t("news::newNFT")}</h1>
        <div className="nft-grid">
          {nftStore.NFT?.map((el, i) => {
            if (i < 6) {
              return <CatalogItem key={"same" + i} el={el} />;
            }
          })}
        </div>
        <CatalogButtonWrapper>
          <Link to="/p/catalog">
            <ButtonLight width="174px" className={"custom-bg-gradient"}>
              {t("button::catalog")}
            </ButtonLight>
          </Link>
        </CatalogButtonWrapper>
      </LastNft>

      <div
        className="main-container"
        style={{
          // backgroundColor: "black",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <Credits />
      </div>

      {/* <FAQ /> */}
    </SmoothAppearance>
  );
});

export default Home;
