import React from "react";
import Modal from "../components/Modal";
import { ModalsEnum, ModalStore } from "../stores/ModalStore";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import {
  SubmitButton,
  TextAreaInput,
  TextInput,
  Title,
} from "../shared/forms/forms";
import { UserStore } from "../stores/user/UserStore";
import { toast } from "react-toastify";
import { NFTstore } from "../stores/NFTstore.";
import Web3 from "web3";
import { WithTranslation, withTranslation } from "react-i18next";
interface IConnectModalProps extends WithTranslation {
  data: { id: string; redirect: boolean; history?: any };
  idx: number;
}

interface IConnectModalState {}

@observer
class СollectionModal extends React.Component<
  IConnectModalProps,
  IConnectModalState
> {
  @resolve(ModalStore)
  protected declare readonly modalStore: ModalStore;
  @resolve(UserStore)
  protected declare readonly userStore: UserStore;
  @resolve(NFTstore)
  protected declare readonly nftStore: NFTstore;

  state = {
    name: "",
    description: "",
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = () => {
    this.userStore
      .createCollection(this.state)
      .then((res) => {
        console.log(res);
        toast.success(this.props.t("collections::created"));
        this.modalStore.hideModal(this.props.idx);
        this.userStore.getCollections("self");
      })
      .catch((err) => {
        console.log(err);
        let msg = err.response.data.err;
        err?.message && toast.error(msg);
      });
  };
  render() {
    const { t } = this.props;
    return (
      <Modal modalKey={ModalsEnum.Collections} idx={this.props.idx}>
        <Title>{t("collections::create")}</Title>
        <TextInput
          title={t("collections::title")}
          type="text"
          name="name"
          onChange={this.onChange}
          border
        ></TextInput>
        <TextAreaInput
          title={t("collections::description")}
          type="text"
          name="description"
          onChange={this.onChange}
          border
        ></TextAreaInput>
        <SubmitButton className="border-grarient" onClick={this.onSubmit}>
          {t("collections::cr")}{" "}
        </SubmitButton>
      </Modal>
    );
  }
}
export default withTranslation()(СollectionModal);
