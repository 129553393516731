import { useInjection } from "inversify-react";
import { NFTstore } from "../../../stores/NFTstore.";
import { useLanguageContext } from "../../../context/LanguageContextProvider";
import { SmoothAppearance } from "../../../shared/layout/smoothAppearance";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getNewsLanguageIndex } from "../../../utils/getNewsLanguageIndex";
import NewsItem, { INews } from "../../../components/newsItem";
import { observer } from "mobx-react";
import SwitchMenu, { IOption } from "../../../components/switchMenu";
import CatalogItem from "../../Catalog/catalogItem";

import ProfileItem, { IProfileItem } from "../../../components/profileItem";
import { ButtonLight } from "./../../../shared/buttons/buttons";
import { useTranslation } from "react-i18next";

export interface ISearchSetting {
  isVisible: boolean;
  name: string;
  entity: string;
}

export interface ISearchParams {
  search?: string;
  skip?: number;
  by?: string;
}

const SearchPage = observer(() => {
  const nftStore = useInjection(NFTstore);
  const { t } = useTranslation();

  const { language } = useLanguageContext();

  const [swithMenuState, setSwithMenuState] = useState<string>("");
  const [skip, setSkip] = useState<number>(0);

  const getParams = (skipParam: number = skip): ISearchParams => {
    const searchvalue = nftStore.searchField
      ? { search: nftStore.searchField }
      : {};
    const typeValue = swithMenuState ? { by: swithMenuState } : {};
    const skipValue = skipParam ? { skip: skipParam } : {};
    return {
      ...skipValue,
      ...searchvalue,
      ...typeValue,
    };
  };

  useEffect(() => {
    nftStore.getAllSearchSettings();
  }, []);

  useEffect(() => {
    setSkip(0);
    nftStore.getSearchContent(getParams(0));
  }, [nftStore.searchField, swithMenuState]);

  useEffect(() => {
    nftStore.getSearchContent(getParams());
  }, [skip]);

  const getSwitchOptions = (options: ISearchSetting[] | null): IOption[] => {
    let result = [{ label: "button::all", value: "" }];
    const getParamLabel = (param: string): string => {
      switch (param) {
        case "news":
          return "navbar::news";
        case "nfts":
          return "sidebar::nft";
        case "users":
          return "sidebar::users";
      }
    };

    options &&
      options
        .filter((item) => item.isVisible)
        .forEach((option) => {
          result.push({
            value: option.entity,
            label: getParamLabel(option.entity),
          });
        });

    return result;
  };

  const news = nftStore.search?.news;
  const users = nftStore.search?.users;
  const nfts = nftStore.search?.nfts;

  const isShowPaginationButton =
    (users?.length >= 10 || nfts?.length >= 10 || news?.length >= 10) &&
    nftStore.searchButton &&
    swithMenuState;

  console.log(
    users?.length >= 10 || nfts?.length >= 10 || news?.length >= 10,
    nftStore.searchButton,
    swithMenuState
  );

  const switchOptions = getSwitchOptions(nftStore.searchSettings);

  return (
    <SmoothAppearance trigger={true}>
      <Wrapper>
        <Container className="main-container">
          <SwitchMenu
            options={switchOptions}
            setSwithMenuState={setSwithMenuState}
            swithMenuState={swithMenuState}
          />
          {news && news.length > 0 && (
            <>
              <BlockTitle>{t("navbar::news")}</BlockTitle>
              <NewsContent>
                {news &&
                  news.map((news: INews, index: number) => {
                    const langIndex = getNewsLanguageIndex(news, language);

                    return (
                      <NewsItem
                        news={news}
                        index={index}
                        langIndex={langIndex}
                      />
                    );
                  })}
              </NewsContent>
            </>
          )}
          {nfts && nfts.length > 0 && (
            <>
              <BlockTitle>{t("sidebar::nft")}</BlockTitle>
              <BlockContent className="ct-contentGrid ct-contentGridSearch">
                {nfts &&
                  nfts?.map((el, i) => {
                    return (
                      <CatalogItem
                        key={"cat-item" + el._id}
                        el={el}
                        isAuc={i % 2 === 0 ? true : false}
                        showDelayTokens={false}
                      />
                    );
                  })}
              </BlockContent>
            </>
          )}
          {users && users.length > 0 && (
            <>
              <BlockTitle>{t("sidebar::users")}</BlockTitle>
              <BlockContent className="ct-contentGrid ct-contentGridSearch">
                {users &&
                  users.map((user: IProfileItem) => {
                    return <ProfileItem user={user} />;
                  })}
              </BlockContent>
            </>
          )}

          {isShowPaginationButton && (
            <ButtonWrapper>
              <ButtonLight
                className="custom-bg-gradient"
                width="200px"
                onClick={() => setSkip(skip + 10)}
              >
                {t("button::showMore")}
              </ButtonLight>
            </ButtonWrapper>
          )}
        </Container>
      </Wrapper>
    </SmoothAppearance>
  );
});

export default SearchPage;

const Wrapper = styled.div`
  padding: 50px 0;
  color: #fff;
`;

const Container = styled.div``;
const NewsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const BlockTitle = styled.h2`
  text-tranform: uppercase;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
`;
const BlockContent = styled.div`
  margin-bottom: 30px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;
