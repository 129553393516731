import React from "react";
import Modal from "../components/Modal";
import { ModalsEnum, ModalStore } from "../stores/ModalStore";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { SubmitButton, TextInput, Title } from "../shared/forms/forms";
import { UserStore } from "../stores/user/UserStore";
import { toast } from "react-toastify";
import { NFTstore } from "../stores/NFTstore.";
import Web3 from "web3";
import { WithTranslation, withTranslation } from "react-i18next";
interface IConnectModalProps extends WithTranslation {
  data: { text: string };
  idx: number;
}

interface IConnectModalState {}

@observer
class WithdrawModal extends React.Component<
  IConnectModalProps,
  IConnectModalState
> {
  @resolve(ModalStore)
  protected declare readonly modalStore: ModalStore;
  @resolve(UserStore)
  protected declare readonly userStore: UserStore;
  @resolve(NFTstore)
  protected declare readonly nftStore: NFTstore;
  state = { value: 0, address: "" };
  onChange = (e) => {
    this.setState({ address: e.target.value });
  };
  onSum = (e) => {
    let web3 = new Web3(process.env.RPC_URL_56);
    this.setState({ value: web3.utils.toWei(e.target.value, "ether") });
  };
  onSubmit = (e) => {
    e.preventDefault();
    this.userStore.withdraw(this.state).then((res) => {
      this.modalStore.hideModal(this.props.idx);
      toast.success(this.props.t("toasts::sent"));
    });
  };
  render() {
    return (
      <Modal modalKey={ModalsEnum.Withdraw} idx={this.props.idx}>
        <Title>{this.props.t("modals::widmoney")}</Title>
        <form onSubmit={this.onSubmit}>
          <TextInput
            required
            title={this.props.t("modals::adress")}
            type="text"
            onChange={this.onChange}
            border
          ></TextInput>
          <TextInput
            required
            title={this.props.t("modals::sum")}
            type="number"
            onChange={this.onSum}
            border
          ></TextInput>
          <SubmitButton className="border-grarient" type="submit">
            {this.props.t("modals::confirm")}
          </SubmitButton>
        </form>
      </Modal>
    );
  }
}
export default withTranslation()(WithdrawModal);
