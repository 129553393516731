import { SmoothAppearance } from "../../../shared/layout/smoothAppearance";
import styled from "styled-components";
import "react-quill/dist/quill.snow.css";
import { useInjection } from "inversify-react";
import { NFTstore } from "../../../stores/NFTstore.";
import { UserStore } from "../../../stores/user/UserStore";
import { useEffect } from "react";
import { useParams } from "react-router";

import { observer } from "mobx-react";
import { getNewsLanguageIndex } from "./../../../utils/getNewsLanguageIndex";
import { useLanguageContext } from "../../../context/LanguageContextProvider";

const Page = styled.div`
  padding: 50px 0;
  color: #fff;
`;
const Container = styled.div``;
const PageTitle = styled.h2`
  text-tranform: uppercase;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 15px;
`;

const PageContent = styled.p`
  font-size: 16px;
`;

const PageContentWrapper = styled.div`
  border: none !important;
`;

interface IParams {
  id: string;
}

const AdditionalPage = observer(() => {
  const nftStore = useInjection(NFTstore);
  const userStore = useInjection(UserStore);
  const { id: url } = useParams<IParams>();
  const { language } = useLanguageContext();

  const pageDetail = nftStore.additionalPageDetail;
  const langIndex = getNewsLanguageIndex(pageDetail, language);

  const pages = nftStore.additionalPages;

  const id = pages && pages.find((page) => page.url === url)._id;

  useEffect(() => {
    id && nftStore.getAllAdditionalPageDetail(id);
  }, [id]);

  useEffect(() => {
    if (nftStore.additionalPageDetail) {
      const tagsValue = `<title>${nftStore.additionalPageDetail?.translations[langIndex]?.metaTite}</title><meta name="description" content="${nftStore.additionalPageDetail?.translations[langIndex]?.metaDescription}" />`;
      userStore.setSeo(tagsValue);
    }
  }, [nftStore.additionalPageDetail, language]);

  return (
    <SmoothAppearance trigger={pageDetail}>
      <Page>
        <Container className="main-container">
          <PageTitle>{pageDetail?.translations[langIndex]?.h1}</PageTitle>

          <PageContentWrapper className="ql-container ql-snow">
            <PageContent
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: pageDetail?.translations[langIndex]?.content,
              }}
            />
          </PageContentWrapper>
        </Container>
      </Page>
    </SmoothAppearance>
  );
});

export default AdditionalPage;
