import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useState } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import {
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../buttons/dropdownMenu";
import { Chevron, NavButton } from "../../../shared/buttons/buttons";
import { UIStore } from "../../../stores/uiStore/uiStore";
import { isProd } from "../../../constants";
import { NFTstore } from "../../../stores/NFTstore.";
import console from "console";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../../context/LanguageContextProvider";

const NavbarButton = observer(({ link, i }) => {
  let match = useRouteMatch();
  const nftStore = useInjection(NFTstore);
  const [hover, setHover] = useState(false);
  const { i18n } = useTranslation();
  const { setLanguage } = useLanguageContext();

  const changeLang = (lang) => {
    let l = lang === "Русский" ? "ru" : "en";
    i18n.changeLanguage(l);
    localStorage.setItem("lang", l);
    setLanguage(l);
  };
  if (i <= 4 && isProd) {
    return <NavButton isHover={hover}></NavButton>;
  }

  return (
    <NavButton
      isHover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        borderColor: hover ? "red" : "",
        display: i === 5 && "block",
      }}
    >
      {link?.isOtherLink ? (
        <a
          style={{
            textDecoration: "none",
            color: "#fff",
            fontFamily: "Releway-medium",
          }}
          href={link.path}
        >
          {link.title}
        </a>
      ) : (
        <NavLink
          style={{
            textDecoration: "none",
            color: "#fff",
            fontFamily: "Releway-medium",
          }}
          activeStyle={{
            fontFamily: link.path ? "Releway-bold" : "Releway-medium",
          }}
          to={
            link.path
              ? `${match.path}${link.path}`
              : window.location.pathname + window.location.hash
          }
        >
          {/* {link.title} */}
          {link.dropdown && link.iconLink ? (
            <img src={link.iconLink} alt="menu" width={20} height={20} />
          ) : (
            link.title
          )}
        </NavLink>
      )}
      {/* {link.dropdown && !link.isGamburger && (
        <Chevron src={require("../../../images/chevron.png")} />
      )} */}

      {link.dropdown && hover && (
        <DropdownMenu
          className="border-grarient"
          style={{ top: "25px", right: "-10px" }}
          onClick={() => setHover(false)}
        >
          {link.dropdown.map((el, i) => {
            return (
              <NavLink
                style={{ textDecoration: "none" }}
                to={
                  link.path
                    ? `${match.path}${el.path}`
                    : window.location.pathname + window.location.hash
                }
                /*only for catalog*/ onClick={() =>
                  el.tag
                    ? nftStore.setNavbarCatalogFilter(["tags", el.tag])
                    : link.path === null
                    ? changeLang(el.title)
                    : ""
                }
              >
                <DropdownButton>{el.title}</DropdownButton>
              </NavLink>
            );
          })}
        </DropdownMenu>
      )}
    </NavButton>
  );
});

export default NavbarButton;
