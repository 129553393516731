import PrivatePages from "../../components/auth/auth";
import { observer } from "mobx-react";
import "./profile.sass";
import { useEffect, useState } from "react";
import { ButtonLight } from "../../shared/buttons/buttons";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import styled from "styled-components";
import CreateNFT from "./nft/create";
import Dashboard from "./lk/dashboard";
import BeAnArtist from "./lk/beAnArtist";
import Sidebar from "./lk/sideProfile";
import ArtistProfile from "./artistProfile";
import SidebarArtist from "./lk/sideArtist";
import { url } from "../../components/auth/helper";
import { UsersStore } from "../../stores/users/UsersStore";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";
import { useTranslation } from "react-i18next";

export const Path = process.env.REACT_APP_IP;

const Container = styled.div`
  @media (max-width: 1000px) {
    flex-direction: column;
    & .profileBlock {
      display: none;
    }
    & .mobileProfile {
      display: block;
    }
  }
  @media (min-width: 1001px) {
    & .profileBlock {
      display: block;
    }
    & .mobileProfile {
      display: none;
    }
  }
`;
const HiddenInput = styled.input`
  width: 300vw;
  transform: translateX(-300vw);
  cursor: pointer;
  height: 169px;
  position: absolute;
  transition: 500ms ease all;
  outline: none;
  & button {
    display: none;
  }
  &:hover {
    box-shadow: 0px 0px 20px grey;
    opacity: 1;
  }
`;
const Banner = styled.img`
  width: 100%;
  height: 169px;
  object-fit: cover;
  object-position: top;
`;
interface stateType {
  user_id: string;
}

const Profile = observer(({ match }) => {
  const userStore = useInjection(UserStore);
  const usersStore = useInjection(UsersStore);
  const location = useLocation();
  const { t } = useTranslation();
  const [auth, setAuth] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [otherUser, setOtherUser] = useState(false);
  const { state } = useLocation<stateType>();
  const userId = state?.user_id;

  useEffect(() => {
    if (userId && !usersStore.user) {
      console.log("zzzzzzzzzzzzz 2");
      usersStore.getOneUser(userId).then((res) => setLoaded(true));
    } else if (!userId && !usersStore.user) {
      console.log("zzzzzzzzzzzzz 3");
      usersStore
        .getOneUser(
          location.pathname.split("/").pop() +
            (location.hash ? location.hash.split("/").shift() : "")
        )
        .then((res) => setLoaded(true));
    }
    return () => {
      usersStore.clearUser();
    };
  }, []);
  useEffect(() => {
    console.log(userId, "kekekekekk", location.pathname.split("/")[2]);

    if (userId !== userStore?.user?.user_id && userId) {
      setOtherUser(true);
    }
  }, [userId]);
  useEffect(() => {
    // console.log(location.pathname.split('/')[5],'ebun')
    if (location.pathname.split("/")[3] === "me") {
      setOtherUser(false);
    }
  }, [location.pathname]);
  useEffect(() => {
    setTimeout(() => setAuth(userStore.isAuth), 500);
  }, [location]);

  const changeBanner = (e) => {
    if (e.target.file?.size > 2000000)
      return toast.error("Image size must be less then 2MB");

    const BannerData = new FormData();
    BannerData.append("banner", e.target.files[0]);
    userStore.ChangeBanner(BannerData).then(() => {
      toast.success(t("toasts::banner"));
    });
  };
  useEffect(() => {
    console.log(otherUser);
  }, [otherUser]);
  return (
    <SmoothAppearance trigger={loaded}>
      {!otherUser ? (
        userStore.user?.banner && !userStore.banner ? (
          <>
            <Banner src={url + "/" + userStore.user?.banner} />

            <HiddenInput
              type="file"
              onChange={changeBanner}
              title="Нажмите чтобы выбрать баннер"
            />
          </>
        ) : userStore.banner && !userStore.user?.banner?.includes("banner") ? (
          <>
            <Banner src={url + "/" + userStore.banner} />
            <HiddenInput
              type="file"
              onChange={changeBanner}
              title="Нажмите чтобы выбрать баннер"
            />
          </>
        ) : (
          <>
            <Banner src={require("../../images/profileHeader.png")} />
            <HiddenInput
              type="file"
              onChange={changeBanner}
              title="Нажмите чтобы выбрать баннер"
            />
          </>
        )
      ) : usersStore.user?.banner &&
        !usersStore.user?.banner?.includes("banner") ? (
        <Banner src={url + "/" + usersStore.user?.banner} />
      ) : usersStore.user?.banner?.includes("banner") ? (
        <Banner src={require("../../images/profileHeader.png")} />
      ) : (
        <Banner src={require("../../images/profileHeader.png")} />
      )}

      <Container className="main-container lk-container ">
        <Switch>
          <Route path={`${match.path}/me`} component={Sidebar} />
          <Route path={`${match.path}/artist/:id`} component={SidebarArtist} />
        </Switch>
        <Switch>
          <Route path={`${match.path}/me`} component={Dashboard} />
          <Route path={`${match.path}/artist/:id`} component={ArtistProfile} />
        </Switch>
      </Container>
    </SmoothAppearance>
  );
});

export default Profile;

{
  /* <ButtonLight width='180px'>
        <label  htmlFor="avatar-upload">Изменить</label>
        <input id='avatar-upload' style={{display:'none'}} type={'file'} onChange={uploadAvatar}></input>
    </ButtonLight> */
}

{
  /* <div className='lk-referal' style={{height: '50px'}}>
        {window.location.origin}?ref={userStore.user?.referal_code}
        <CopyToClipboard text={`${window.location.origin}/?ref=${userStore.user?.referal_code}`}>
        <img style={{cursor:'pointer'}} src={require('../../images/copy-icon.png')} onClick={linkToClipboard} />
        </CopyToClipboard>
    </div> */
}

{
  /* {activePage==='dashboard'&&<Dashboard/>}
{activePage==='create'&& userStore.user?.user_type==='artist'&& <CreateNFT close={()=>setActivePage('dashboard')}/>}
{activePage==='create'&& userStore.user?.user_type==='user' && <BeAnArtist close={()=>setActivePage('dashboard')}/>} */
}
