import myNFTIcon from "./../images/icons/table.svg";
import createdIcon from "./../images/icons/hammer.svg";
import collectionsIcon from "./../images/icons/box-archive.svg";
import wishlistIcon from "./../images/icons/star.svg";
import blogIcon from "./../images/icons/blog.svg";
import dIcon from "./../images/icons/3d.png";
import profileIcon from "./../images/icons/user.svg";
import referalsIcon from "./../images/icons/users.svg";
import walletIcon from "./../images/icons/wallet.svg";

export interface INavItem {
  path?: string;
  text?: string;
  icon?: HTMLImageElement;
  title?: string;
}

export const profileNav: Array<INavItem> = [
  { title: "sidebar::nft" },
  {
    title: null,
    path: "",
    text: "sidebar::myNFT",
    icon: myNFTIcon,
  },
  {
    title: null,
    path: "created",
    text: "sidebar::created",
    icon: createdIcon,
  },
  {
    title: null,
    path: "collections",
    text: "sidebar::collections",
    icon: collectionsIcon,
  },
  {
    title: null,
    path: "wishlist",
    text: "sidebar::wishlist",
    icon: wishlistIcon,
  },
  { title: "sidebar::social" },
  {
    title: null,
    path: "blog",
    text: "sidebar::blog",
    icon: blogIcon,
  },
  {
    title: null,
    path: "3d",
    text: "sidebar::3d",
    icon: dIcon,
  },
  { title: "sidebar::managment" },
  {
    title: null,
    path: "profile",
    text: "sidebar::myData",
    icon: profileIcon,
  },
  {
    title: null,
    path: "referals",
    text: "sidebar::referals",
    icon: referalsIcon,
  },
  {
    title: null,
    path: "wallet",
    text: "sidebar::wallet",
    icon: walletIcon,
  },
];
