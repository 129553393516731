import axios from "axios";
import { injectable } from "inversify";
import { action, makeObservable, observable } from "mobx";
import "reflect-metadata";
import { innerBackend, setAuthToken, url } from "../components/auth/helper";
import { RootStore } from "./RootStore";
import { toast } from "react-toastify";

import { web3 } from "../constants";
import addXmlHotspots from "../utils/addXmlHotspot";
import { ICategory } from "./../pages/profile/nft/types";
import { INews } from "../components/newsItem";
import { ISearchSetting } from "./../pages/admin/Search";
import { IProfileItem } from "../components/profileItem";

@injectable()
export class NFTstore {
  @observable NFT: any;
  @observable NFTbutton: boolean;
  @observable filters = [];
  @observable sort: any;
  @observable searchField = "";
  @observable page = 1;
  @observable oneNFT: any;
  @observable address: string = null;
  @observable socket: any = null;
  @observable tags: any;
  @observable categories: ICategory[] | null;
  @observable hidden: any;
  @observable mainNFT: any;
  @observable partitions: any;
  @observable partition: any;
  @observable partitionTokens: any;
  @observable partitionPage = 1;
  @observable showDelayTokens = false;
  @observable userGalleries = [];
  @observable galleries = [];
  @observable oneGallery = {};
  @observable news: INews[] | null = null;
  @observable newsDetail = null;
  @observable additionalPages = null;
  @observable additionalPageDetail = null;
  @observable search: {
    nfts: any;
    users: IProfileItem[] | null;
    news: INews[] | null;
  } = null;
  @observable searchSettings: ISearchSetting[] | null = null;
  @observable searchButton: boolean;
  @observable offerNFT: any;

  public constructor(private readonly rootStore: RootStore) {
    makeObservable(this);
  }

  //add and change user metamask address
  //put /user/metamask/:address
  chnagneAddress = async (address) => {
    try {
      if (address) {
        const res = innerBackend.put(`${url}/user/metamask/${address}`);
        this.address = address;
      }
    } catch (err) {
      console.log(err);
    }
  };

  //nft creation
  nftCreation = async (formDataNFT: FormData) => {
    const res = await innerBackend.post(`${url}/nft/upload`, formDataNFT);
  };

  create3D = async (title: string, type: string, hotspots, text: string) => {
    let xml = addXmlHotspots(type, hotspots);
    let body = {
      title: title,
      type: type,
      saveHotspot: xml,
    };
    try {
      const res = await innerBackend.post(`${url}/gallery/create`, body);
      toast.success(text);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  showAll3Ds = async () => {
    try {
      const res = await innerBackend.get(`${url}/gallery/galleries`);
      console.log("%cNFTstore..ts line:90 res", "color: #007acc;", res.data);
      this.galleries = res.data.galleries;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  showUser3Ds = async (id) => {
    try {
      const res = await innerBackend.get(`${url}/gallery/galleries/${id}`);
      console.log("%cNFTstore..ts line:90 res", "color: #007acc;", res.data);
      this.userGalleries = res.data.galleries;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  showGalleryById = async (id) => {
    try {
      const res = await innerBackend.get(`${url}/gallery/gallery/${id}`);
      console.log("%cNFTstore..ts line:103 res", "color: #007acc;", res);
      this.oneGallery = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  deleteGallery = async (id, text) => {
    try {
      const res = await innerBackend.delete(`${url}/gallery/gallery/${id}`);
      console.log("%cNFTstore..ts line:103 res", "color: #007acc;", res);
      toast.success(text);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  increaseUsersCount = async (id) => {
    console.log(id);
    try {
      const res = await innerBackend.get(`${url}/nft/views/increase/${id}`);
      console.log(res);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  getAllTags = async () => {
    console.log(123);
    try {
      const res = await innerBackend.get(`${url}/admin/tag/get`);
      console.log("response", res.data);
      this.tags = res.data;
      // console.log(11111);
      return res.data;
    } catch (err) {
      console.log("Errrrrrrrror");
      let msg = err?.response?.data?.err;
      err?.message && toast.error(msg);
    }
  };

  //put /nft/buy/:token_mongo_id
  buyNFT = async (id, ownerId) => {
    try {
      const res = await innerBackend.post(`${url}/nft/${id}/setOwner`, {
        owner: ownerId,
      });
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };

  betNFT = async (id, value) => {
    let weiVal = web3.utils.toWei(value.toString(), "ether");
    // console.log(weiVal)
    try {
      const res = await innerBackend.put(`${url}/auc/bid/${id}`, {
        bid: weiVal,
      });
      // console.log(weiVal)
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  getNFTcatalog = async () => {
    // console.log('start2', `${url}/nft/findall?page=${this.page}${this.sort?this.sort:''}`)

    try {
      const params =
        this.filters.length > 0 ? new URLSearchParams(this.filters) : undefined;
      //Закоментил временно
      // const showDalay = this.showDelayTokens
      //   ? "&auction.delay=true"
      //   : "&auction.delay=false";
      const showDalay = "";
      const res = await axios.get(
        `${url}/nft/findall?page=${this.page}${showDalay}${
          this.sort ? this.sort : ""
        }`,
        { params }
      );
      console.log("get nft catalog", res.data);
      this.NFT = this.page === 1 ? res.data : this.NFT.concat(res.data);
      this.NFTbutton = res.data.length >= 10;
    } catch (err) {
      console.log("error tut", err.response);
    }
  };
  getOfferNFT = async () => {
    const res = await axios.get(`${url}/nft/topSortIndex`);
    this.offerNFT = res.data;
  };
  getOneNFT = async (id) => {
    console.log("one nft", id);
    if (id) {
      const res = await axios.get(`${url}/nft/find/${id}`);
      console.log("data", res.data);
      this.oneNFT = res.data;
    }
  };
  clearNFTState = async () => {
    this.oneNFT = null;
  };
  getHiddenContent = async (id) => {
    console.log("one nft", id);
    const res = await innerBackend.get(`${url}/nft/get/hidden/${id}`);
    console.log(res.data, "data");
    this.hidden = res.data;
  };
  removeHidden = async () => {
    this.hidden = null;
  };
  navSearchBarCatalogFilter = async (search) => {
    this.searchField = search;
  };
  setNavbarCatalogFilter = async (filter) => {
    this.searchField = "";
    if (filter) {
      this.filters = [filter];
      this.page = 1;
      this.getNFTcatalog();
    } else {
      this.filters = [];
      this.page = 1;
      this.getNFTcatalog();
    }
  };
  toggleFilter = async (filter) => {
    const id = this.filters.findIndex((el) => el[1] === filter[1]);
    console.log("toggle filter aaaaaaaaaa", filter);
    console.log(id);
    if (id >= 0) {
      this.filters.splice(id, 1);
    } else {
      this.filters = [...this.filters, filter];
    }
    this.page = 1;
    this.getNFTcatalog();
  };
  toggleSort = async (field, way) => {
    this.page = 1;
    this.sort = `&sort=${field}&sort=${way}`;
    this.getNFTcatalog();
  };
  loadNewPage = async () => {
    this.page = this.page + 1;
    this.getNFTcatalog();
  };
  resetPage = async () => {
    this.page = 1;
    this.partitionPage = 1;
    return;
  };

  socketConnect = (socket) => {
    this.socket = socket;
  };

  //socket setup for auction
  onBet = async (nft_id) => {
    this.socket().on(`${nft_id}`, (res) => {
      console.log(res, "respon");
      this.oneNFT = res.data;
    });
  };

  getMostExpensiveNFT = async () => {
    try {
      const res = await innerBackend.get(`${url}/news/nft/price`);
      console.log("price nft", res);
      this.mainNFT = res.data;
    } catch (err) {
      console.log(err.response);
    }
  };

  likeNft = async (id) => {
    try {
      const res = await innerBackend.post(`${url}/nft/like/${id}`);
      console.log("liked: ", res);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  getAllPartitions = async () => {
    const res = await axios.get(`${url}/partitions/all`);
    console.log("all partitions", res.data);
    this.partitions = res.data;
    return res.data;
  };
  getOnePartition = async (part_id) => {
    const res = await axios.get(
      `${url}/partitions/get/${part_id}?page=${this.partitionPage}`
    );
    console.log("one partition", res.data);
    this.partition = res.data;
    this.partitionTokens =
      this.partitionPage === 1
        ? res.data.tokens
        : this.partitionTokens.concat(res.data.tokens);
  };
  partitionNewPage = async (part_id) => {
    this.partitionPage = this.partitionPage + 1;
    this.getOnePartition(part_id);
  };
  clearPartition = () => {
    this.partition = null;
  };
  getAllNews = async () => {
    const res = await axios.get(`${url}/news/all`);
    this.news = res.data;
  };
  getNewsDetail = async (id: string) => {
    const res = await axios.get(`${url}/news/get/${id}`);
    this.newsDetail = res.data;
  };
  getAllAdditionalPages = async () => {
    const res = await axios.get(`${url}/page`);
    this.additionalPages = res.data;
  };
  getAllAdditionalPageDetail = async (pageUrl: string) => {
    const res = await axios.get(`${url}/page/${pageUrl}`);
    this.additionalPageDetail = res.data;
  };
  //categories
  async getAllCategories() {
    try {
      const res = await innerBackend.get(`${url}/nft/category`);
      this.categories = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  //search
  async getSearchContent(params) {
    try {
      const res = await innerBackend.get(`${url}/search`, { params });
      this.search = params?.skip
        ? {
            ...this.search,
            [`${params?.by}`]: [
              ...this.search[`${params?.by}`],
              ...res.data[`${params?.by}`],
            ],
          }
        : res.data;
      this.searchButton = params?.by && res.data[`${params?.by}`].length >= 10;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async getAllSearchSettings() {
    try {
      const res = await innerBackend.get(`${url}/setting/search`);
      this.searchSettings = res.data.search;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
}
//@POST /users/auth {email:email,password:password} - авторизация
//его в POST /usman/validate/:id
//POST /usman/auth {password,field,content}
//филд - email или public_key
//POST /usman/auth {password,field,content}
