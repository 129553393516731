import { useTranslation } from "react-i18next";
import { ButtonText } from "../../shared/buttons/buttons";
import styled from "styled-components";
import React from "react";

export interface IOption {
  label: string;
  value: string;
}

interface IParams {
  options: IOption[];
  setSwithMenuState: (value: string) => void;
  swithMenuState: string;
  handleChange?: () => void;
}

const SwitchMenu: React.FC<IParams> = ({
  options,
  setSwithMenuState,
  swithMenuState,
  handleChange = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {options.map(({ label, value }, index: number) => {
        return (
          <ButtonText
            key={index}
            onClick={() => {
              handleChange();
              setSwithMenuState(value);
            }}
            style={{
              borderColor: swithMenuState === value ? "#894ADA" : "",
            }}
          >
            {t(label)}
          </ButtonText>
        );
      })}
    </Wrapper>
  );
};

export default SwitchMenu;

const Wrapper = styled.div`
  & button {
    font-family: Releway-semiBold;
    font-size: 18px;
    margin-right: 18px;
    border-bottom: 2px solid transparent;
    margin-bottom: 20px;
    &:hover {
      border-color: #894ada;
    }
  }
`;
