import React from "react";
import Modal from "../components/Modal";
import { ModalsEnum, ModalStore } from "../stores/ModalStore";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import {
  InputHolder,
  SubmitButton,
  TextInput,
  Title,
} from "../shared/forms/forms";
import { UserStore } from "../stores/user/UserStore";
import { toast } from "react-toastify";
import { NFTstore } from "../stores/NFTstore.";
import Web3 from "web3";
import { WithTranslation, withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import styled from "styled-components";

interface IConnectModalProps extends WithTranslation {
  data: { id: string; redirect: boolean; history?: any };
  idx: number;
}

interface IConnectModalState {}

@observer
class AucTokenModal extends React.Component<
  IConnectModalProps,
  IConnectModalState
> {
  @resolve(ModalStore)
  protected declare readonly modalStore: ModalStore;
  @resolve(UserStore)
  protected declare readonly userStore: UserStore;
  @resolve(NFTstore)
  protected declare readonly nftStore: NFTstore;
  state = {
    startPrice: "",
    fullPrice: "",
    date: "",
    delay: false,
    startDate: "",
  };
  componentDidMount() {
    let now = new Date();
    this.setState({ startDate: now });
  }

  onChange = (e) => {
    let web3 = new Web3(process.env.RPC_URL_56);
    this.setState({
      [e.target.name]: web3.utils.toWei(e.target.value, "ether"),
    });
  };
  onDayChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  startDateChange = (date) => {
    const difference = Date.parse(date) - Date.now();
    if (difference < -5000) return toast.error("Неправильная дата начала");
    this.setState({ startDate: date, delay: true });
  };
  onSubmit = () => {
    if (
      this.state.date.length == 0 ||
      this.state.fullPrice.length == 0 ||
      this.state.startPrice.length == 0
    ) {
      toast.error(this.props.t("toasts::allFields"));

      return;
    }
    this.userStore
      .startAuction(this.props.data.id, {
        startPrice: this.state.startPrice,
        fullPrice: this.state.fullPrice,
        date: this.state.date,
        startDate: this.state.startDate,
        delay: this.state.delay,
      })
      .then((res) => {
        // console.log(res)
        toast.success(this.props.t("toasts::onSale"));
        this.modalStore.hideModal(this.props.idx);
        if (this.props.data.redirect) {
          this.props.data.history.push("../../../p/catalog");
        }
      })
      .catch((err) => {
        //    console.log(err)
        let msg = err.response.data.err;
        err?.message && toast.error(msg);
      });
  };
  render() {
    return (
      <Modal modalKey={ModalsEnum.AucToken} idx={this.props.idx}>
        <Title>{this.props.t("modals::createAuc")}</Title>
        <TextInput
          title={this.props.t("modals::start")}
          type="number"
          name="startPrice"
          onChange={this.onChange}
          border
        ></TextInput>
        <TextInput
          title={this.props.t("modals::buyout")}
          type="number"
          name="fullPrice"
          onChange={this.onChange}
          border
        ></TextInput>
        <TextInput
          title={this.props.t("modals::duration")}
          type="number"
          name="date"
          onChange={this.onDayChange}
          border
        ></TextInput>
        <InputHolder>
          <div>{this.props.t("creation::aucDateStart")}</div>
          <DP>
            <DatePicker
              selected={this.state.startDate}
              showTimeSelect
              dateFormat="Pp"
              onChange={this.startDateChange}
            />
          </DP>
        </InputHolder>
        <SubmitButton className="border-grarient" onClick={this.onSubmit}>
          {this.props.t("collections::cr")}
        </SubmitButton>
      </Modal>
    );
  }
}
export default withTranslation()(AucTokenModal);

const DP = styled.div`
  input {
    border-color: #f2e9ea;
  }
`;
