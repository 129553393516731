import PrivatePages from "../../../components/auth/auth";
import { observer } from "mobx-react";
import "../profile.sass";
import { useEffect, useState } from "react";
import { ButtonLight, ButtonText } from "../../../shared/buttons/buttons";
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import styled from "styled-components";
// import { faAd, faBlog, faBoxes, faChartLine, faHammer, faPalette, faPallet, faShoppingCart, faStar, faTh, faUser, faUsers, faWallet } from '@fortawesome/free-solid-svg-icons'
import { MenuNav } from "../../../shared/navigation/navlink";
import { Divider, Menu, MobileProfile } from "./styled";
import {
  DropdownItem,
  DropdownLink,
  DropdownMenu,
} from "../../../shared/buttons/dropdownMenu";
import { useTranslation } from "react-i18next";
import { useActiveWeb3React } from "../../../hooks/web3";
import { useWeb3React } from "@web3-react/core";
import { profileNav } from "./../../../utils/constants";
import { getProfileNavValue } from "./../../../utils/getProfileNavValue";

export const Path = process.env.REACT_APP_IP;

const AvatarUpload = styled.div`
  position: relative;
  margin-bottom: 20px;
  & div {
    background-color: white;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    position: absolute;
    left: auto;
    right: auto;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
    box-shadow: 0px 0px 15px 2px white;
  }
  & img {
    width: 180px;
    height: 180px;
    border-radius: 100%;
    margin-bottom: 0px;
    overflow: hidden;
    object-fit: cover;
  }
  & div:hover {
    opacity: 0.5;
  }

  &:before {
    border-radius: 50%;
    margin: -10px;
    padding: 3px;
  }
  @media (max-width: 1000px) {
    & div {
      width: 100px;
      height: 100px;
    }
    & img {
      width: 100px;
      height: 100px;
    }
  }
`;

const Sidebar = observer(({ match }) => {
  const userStore = useInjection(UserStore);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { library } = useWeb3React();

  useEffect(() => {
    if (!userStore.profileChapters) {
      userStore.GetProfileChapters();
    }
  }, []);

  const linkToClipboard = () => {
    toast.success(t("toasts::copied"));
  };
  const uploadAvatar = (e) => {
    if (e.target.file?.size > 2000000)
      return toast.error("Image size must be less then 2MB");

    setAvatar(e.target.files[0]);
    const AvatarData = new FormData();
    AvatarData.append("file", e.target.files[0]);
    userStore.uploadAvatar(AvatarData).then((res) => {
      toast.success(t("toasts::avatar"));
    });
  };

  const createNFT = () => {
    // if(Number(userStore.user?.balance)>32000000000000000) {
    // library.fromWei(userStore.user?.balance.toString(), 'ether')
    history.push(`${match.path}/create_NFT`);
    console.log(userStore.user?.balance, "balance");
    // } else {
    //     toast.error('Для создания NFT минимальный баланс должен быть 0.032 BNB')
    // }
  };

  const finalProfileNavigation = getProfileNavValue(
    userStore.profileChapters || [],
    profileNav
  );

  return (
    <>
      <div className="profileBlock">
        <AvatarUpload className="border-grarient">
          <label style={{ cursor: "pointer" }} htmlFor="avatar-upload">
            <img className="lk-logo" src={Path + "/" + userStore.user?.photo} />
            <div>{t("sidebar::changeAvatar")}</div>
          </label>
        </AvatarUpload>

        <input
          id="avatar-upload"
          style={{ display: "none" }}
          type={"file"}
          onChange={uploadAvatar}
        ></input>

        <div className="lk-name">{userStore.user?.fullname}</div>
        <div className="lk-id">{userStore.user?.user_id}</div>

        {/* <div className="lk-referal">
          {userStore.user?.public_key?.slice(0, 4)}...
          {userStore.user?.public_key?.slice(
            userStore.user?.public_key.length - 5,
            userStore.user?.public_key.length - 1
          )}
          <CopyToClipboard text={`${userStore.user?.public_key}`}>
            <img
              style={{ cursor: "pointer" }}
              src={require("../../../images/copy-icon.png")}
              onClick={linkToClipboard}
            />
          </CopyToClipboard>
        </div> */}

        {/* {location.pathname === "/p/profile/me/create_NFT" ? (
          <ButtonLight
            width="180px"
            onClick={() => history.push(`${match.path}/`)}
          >
            <NavLink to={`${match.path}/`}>{t("sidebar::back")}</NavLink>
          </ButtonLight>
        ) : location.pathname === "/p/profile/me/become_artist" ? (
          <ButtonLight
            width="180px"
            onClick={() => history.push(`${match.path}/`)}
          >
            <NavLink to={`${match.path}/`}>{t("sidebar::back")}</NavLink>
          </ButtonLight>
        ) : userStore.user?.user_type === "artist" ? (
          <ButtonLight width="180px" onClick={createNFT}>
            <p style={{ marginTop: "5%" }}>{t("sidebar::createNFT")}</p>
          </ButtonLight>
        ) : (
          <ButtonLight
            width="180px"
            onClick={() => history.push(`${match.path}/become_artist`)}
          >
            <NavLink to={`${match.path}/become_artist`}>
              {t("sidebar::createNFT")}
            </NavLink>
          </ButtonLight>
        )} */}
        {/* <Divider style={{ margin: "18px 0" }} /> */}
        <Menu className="border-grarient">
          {finalProfileNavigation.map((nav, index) => {
            if (nav?.title) {
              return <h3>{t(nav?.title)}</h3>;
            } else {
              return (
                <MenuNav
                  key={index}
                  exact={true}
                  to={`${match.path}/${nav?.path}`}
                  text={`${t(nav?.text)}`}
                  icon={nav.icon}
                />
              );
            }
          })}
        </Menu>
      </div>
      <MobileProfile className="mobileProfile">
        <div style={{ display: "flex" }}>
          <AvatarUpload
            style={{ width: 100, height: 100, marginRight: 30 }}
            className="border-grarient"
          >
            <label style={{ cursor: "pointer" }} htmlFor="avatar-upload">
              <img
                className="lk-logo"
                src={Path + "/" + userStore.user?.photo}
              />
              <div>{t("sidebar::changeAvatar")}</div>
            </label>
          </AvatarUpload>
          <div style={{ flexGrow: 3 }}>
            <div className="lk-name">{userStore.user?.fullname}</div>
            <div className="lk-id">{userStore.user?.user_id}</div>
            <div style={{ position: "relative" }}>
              <ButtonLight
                style={{ height: "30px", width: "100px" }}
                onClick={() => setOpenMobileMenu(!openMobileMenu)}
              >
                {t("sidebar::menu")}
              </ButtonLight>

              {openMobileMenu && (
                <DropdownMenu
                  style={{ top: "50px", left: "-70px", zIndex: 99999 }}
                  onClick={() => setOpenMobileMenu(!openMobileMenu)}
                >
                  {userStore.user?.user_type === "artist" ? (
                    <DropdownItem
                      icon={require(`../../../images/icons/palette.svg`)}
                    >
                      <DropdownLink
                        exact={true}
                        to={`${match.path}/create_NFT`}
                      >
                        {t("sidebar::createNFT")}
                      </DropdownLink>
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      icon={require(`../../../images/icons/palette.svg`)}
                    >
                      <DropdownLink
                        exact={true}
                        to={`${match.path}/become_artist`}
                      >
                        {t("sidebar::createNFT")}
                      </DropdownLink>
                    </DropdownItem>
                  )}
                  <DropdownItem
                    icon={require(`../../../images/icons/table.svg`)}
                  >
                    <DropdownLink exact={true} to={`${match.path}/`}>
                      {t("sidebar::myNFT")}
                    </DropdownLink>
                  </DropdownItem>
                  <DropdownItem
                    icon={require(`../../../images/icons/hammer.svg`)}
                  >
                    <DropdownLink exact={true} to={`${match.path}/created`}>
                      {t("sidebar::created")}
                    </DropdownLink>
                  </DropdownItem>
                  <DropdownItem
                    icon={require(`../../../images/icons/cart-shopping.svg`)}
                    // icon={faShoppingCart}
                  >
                    <DropdownLink exact={true} to={`${match.path}/selling`}>
                      {t("sidebar::sellings")}
                    </DropdownLink>
                  </DropdownItem>
                  <DropdownItem
                    icon={require(`../../../images/icons/user.svg`)}
                    // icon={faUser}
                  >
                    <DropdownLink exact={true} to={`${match.path}/profile`}>
                      {t("sidebar::myData")}
                    </DropdownLink>
                  </DropdownItem>
                  <DropdownItem
                    icon={require(`../../../images/icons/users.svg`)}
                    //  icon={faUsers}
                  >
                    <DropdownLink exact={true} to={`${match.path}/referals`}>
                      {t("sidebar::referals")}
                    </DropdownLink>
                  </DropdownItem>
                  <DropdownItem
                    icon={require(`../../../images/icons/wallet.svg`)}
                    // icon={faWallet}
                  >
                    <DropdownLink exact={true} to={`${match.path}/wallet`}>
                      {t("sidebar::wallet")}
                    </DropdownLink>
                  </DropdownItem>
                  <DropdownItem
                    icon={require(`../../../images/icons/3d.png`)}
                    // icon={faWallet}
                  >
                    <DropdownLink exact={true} to={`${match.path}/3d`}>
                      {t("sidebar::3d")}
                    </DropdownLink>
                  </DropdownItem>
                  <DropdownItem
                    icon={require(`../../../images/icons/blog.svg`)}
                    // icon={faWallet}
                  >
                    <DropdownLink exact={true} to={`${match.path}/blog`}>
                      {t("sidebar::blog")}
                    </DropdownLink>
                  </DropdownItem>
                </DropdownMenu>
              )}
            </div>
          </div>
        </div>
      </MobileProfile>
    </>
  );
});

export default Sidebar;

{
  /* {userStore.user?.user_type==='artist'&&
            <Be>
                Стать 
                <button onClick={()=>userStore.changeType('user')}>Пользователем</button>
            </Be>
            } */
}
{
  /* {
                    activePage==='dashboard'?
                        <ButtonLight width='180px' style={{width:'max-content'}} onClick={() =>setActivePage('create')}>Создать NFT</ButtonLight>
                        :
                        <ButtonLight width='180px' style={{width:'max-content'}} onClick={() =>setActivePage('dashboard')}>Отмена</ButtonLight>
                    } */
}
