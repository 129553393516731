import styled from "styled-components";

const CreditsWrapper = styled.div`
  background-image: url(${(p: { bgPath: string }) => p.bgPath});
  background-size: cover;
  background-position: top center;
  padding: 100px 0;
  & h2 {
    background: #004af9;
    background: linear-gradient(to right, #48b4bc 0%, #db00ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-size: 64px;
    line-height: 1.2em;
    text-transrorm: uppercase;
    font-weight: 700;
  }
  & h3 {
    background: #004af9;
    background: linear-gradient(to right, #48b4bc 0%, #db00ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 44px;
    line-height: 1.2em;
    font-weight: 700;
  }
  & h4 {
    color: #48b4bc;
    font-size: 28px;
    font-weight: 700;
  }
  & h5 {
    color: #48b4bc;
    font-size: 22px;
    font-weight: 700;
  }
  & p {
    color: #fff;
    font-size: 28px;
    margin-bottom: 30px;
    font-weight: 700;
    &.no-margin {
      margin-bottom: 0px;
    }
    & span {
      font-size: 24px;
      font-weight: 400;
    }
  }
`;

const CreditsTitleWrapper = styled.div`
  padding: 0 5%;
  margin-bottom: 100px;
`;
const CreditsContent = styled.div`
  padding: 0 10%;
`;

const Credits = () => {
  return (
    <CreditsWrapper
      bgPath={require("./../../images/mechanics_credits_full.jpg")}
    >
      <CreditsTitleWrapper>
        <h2>
          Credits of immersive <br />
          performance “Mechanics of Wonder”,
        </h2>
        <h3>EXPO 2020 Dubai, Russian Pavilion</h3>
      </CreditsTitleWrapper>
      <CreditsContent>
        <h3>AUTHOR OF THE EXPOSITION</h3>
        <p>Konstantyn Petrov (Simpateka Entertainment Group)</p>

        <h3>CO-AUTHORS OF THE EXPOSITION</h3>
        <h4>SCIENCE CURATOR:</h4>
        <p>
          Prof. Konstantin Anokhin{" "}
          <span>
            (neuroscientist, Director of the Institute for Advanced Brain
            Research, M.V. Lomonosov Moscow State University, Academician of the
            Russian Academy of Sciences)
          </span>
        </p>

        <h4>ART CURATOR:</h4>
        <p>
          Dmitry Likin{" "}
          <span>(WowHaus co-founder; art director Channel 1 Russia)</span>
        </p>

        <h4>DESIGN:</h4>
        <p>
          Anton Sevastjanov <span>(Simpateka Entertainment Group)</span>
        </p>

        <h4>INVITED EXPERTS:</h4>
        <p className="no-margin">
          Olga Svarnik{" "}
          <span>
            (PhD in Psychophysiology, Leading Scientist in Institute of
            Psychology RAS)
          </span>
        </p>
        <p className="no-margin">
          Ksenia Toropova{" "}
          <span>
            (neurobiologist, Researcher in the Institute for Advanced Brain
            Research, M.V. Lomonosov Moscow State University)
          </span>
        </p>
        <p>
          Olga Ivashkina{" "}
          <span>
            (neurobiologist, Researcher in the Institute for Advanced Brain
            Research, M.V. Lomonosov Moscow State University)
          </span>
        </p>

        <h4>MAIN PERFORMANCE DIRECTOR:</h4>
        <p>
          Lada Oradovskaya <span>(Simpateka Entertainment Group)</span>
        </p>

        <h4>TECHNICAL PRODUCTION:</h4>
        <p>
          <span>Simpateka Entertainment Group:</span>
          <br />
          Arkady Ladokhin
        </p>

        <h4>DIGITAL CONTENT PRODUCTION:</h4>
        <p className="no-margin">
          <span>The main multimedia performance - </span>DREAMLASER
        </p>
        <p>
          <span>Information video production - </span>INTROODE
        </p>

        <h4>MULTIMEDIA CONTENT PRODUCER:</h4>
        <p>
          Agata Likina <span>(Simpateka Entertainment Group)</span>
        </p>

        <h4>MUSIC & SOUND:</h4>
        <p>Music & Sound Design by WAVEME.PRO</p>
        <h5>Producers:</h5>
        <p className="no-margin">
          Konstantin Zaslonov <span> (waveme.pro)</span>
        </p>
        <p className="no-margin">
          Konstantyn Petrov <span>(Simpateka Entertainment Group)</span>
        </p>
        <p>
          Serafima Gavrilova <span>(Dreamlaser)</span>
        </p>

        <h4>MULTIMEDIA:</h4>
        <p>BigScreenShow – Professional Video Systems</p>

        <h4>ENGINEERING:</h4>
        <p>KGGT LLC</p>

        <h4>PAVILION SUPPORTING DIGITAL PLATFORM::</h4>
        <p>UWalk</p>
      </CreditsContent>
    </CreditsWrapper>
  );
};

export default Credits;
