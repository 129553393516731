import { Author, Info, Title } from "../index";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { observer } from "mobx-react";
import { toast } from "react-toastify";

import { useInjection } from "inversify-react";
import { UsersStore } from "../../../stores/users/UsersStore";
import { url } from "../../../components/auth/helper";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { UserStore } from "../../../stores/user/UserStore";
import { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faHeart, faStar } from "@fortawesome/free-solid-svg-icons";
import { NFTstore } from "../../../stores/NFTstore.";
import testPhome from "./../../../images/catalog-img0.png";

import {
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { FacebookIcon } from "react-share";

interface ILike {
  liked: boolean;
  wished: boolean;
}
const WishLikes = styled.div`
  display: flex;
  & div {
    border-radius: 3px;
    background-color: #225373;
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
  }
  & .like {
    padding: 0px 5px;
    margin-left: 5px;
    & svg {
      margin-left: 5px;
      & path {
        stroke: white;
        stroke-width: 35px;
        fill: ${(p: ILike) => (p.liked ? "white" : "transparent")};
      }
    }
  }
  & .views {
    cursor: default;
    padding: 0px 5px;
    margin-left: 5px;
    & svg {
      margin-left: 5px;
      & path {
        /* stroke: white;
    stroke-width: 35px; */
        fill: white;
      }
    }
  }
  & .wish {
    padding: 4px 5px;
    & svg {
      & path {
        stroke: white;
        stroke-width: 35px;
        fill: ${(p: ILike) => (p.wished ? "white" : "transparent")};
      }
    }
  }
`;

const ViewWrapper = styled.div`
  width: 100px;
  height: 41px;
  margin-right: 24px;
  padding: 0 15px;
  font-size: 20px;
  line-height: 1.5em;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px !important;
  display: flex;
  align-items: center;

  & img {
    width: 24px;
    margin-right: 15px;
  }
`;

const ShareMenu = styled.div`
  position: absolute;
  top: 33px;
  right: 0;
`;

const ShareMenuContentWrapper = styled.div`
  padding: 20px;
  display: flex;
`;
const ShareMenuItem = styled.div`
  margin: 0 7px;
  position: relative;
  z-index: 2;
`;

export const BasicInfo = observer(({ oneNFT, history, match }) => {
  const { t } = useTranslation();
  const usersStore = useInjection(UsersStore);
  const userStore = useInjection(UserStore);
  const nftStore = useInjection(NFTstore);
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [wished, setWished] = useState(false);
  const [eng, setEng] = useState(false);

  const [isvisibleShareMenu, setIsvisibleShareMenu] = useState(false);

  const transl = (category) => {
    if (category === "Изображение") {
      return "Image";
    } else if (category === "Векторное изображение") {
      return "Vector Image";
    } else if (category === "Панорамное изображение") {
      return "Panoramic Image";
    } else if (category === "Видео") {
      return "Video";
    } else if (category === "Панорамное видео") {
      return "Panoramic Video";
    } else if (category === "3Д Видео") {
      return "3D Video";
    } else if (category === "3Д Модель") {
      return "3D Model";
    } else if (category === "Интерактивный контент") {
      return "Interactive content";
    } else if (category === "Исключительная лицензия") {
      return "Exclusive rights";
    } else if (category === "Не исключительная лицензия") {
      return "Non-exclusive rights";
    }
  };
  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setEng(true);
    } else {
      setEng(false);
    }
  }, [localStorage.getItem("lang")]);
  useEffect(() => {
    if (userStore.user) {
      const l = nftStore.oneNFT?.likes?.some(
        (id) => id === userStore.user?._id
      );
      const w = userStore.user?.wishlist?.some(
        (wish) => wish._id === nftStore.oneNFT?._id
      );
      setLiked(l);
      setWished(w);
    }
    const v = nftStore.oneNFT?.likes ? nftStore.oneNFT?.likes.length : 0;
    setLikes(v);
  }, [userStore.user, nftStore.oneNFT]);

  const useerRedirect = (user_id) => {
    usersStore.getOneUser(user_id);
    history.push({
      pathname: "/p/profile/artist/" + user_id,
      state: { user_id: user_id },
    });
  };
  const collectionRedirect = (user_id, id) => {
    usersStore.getOneUser(user_id);
    // console.log(id)
    history.push({
      pathname: `/p/profile/artist/${user_id}/collections/${id}`,
      state: { user_id: id },
    });
  };
  const linkToClipboard = () => {
    toast.success(t("toasts::linkCopiedNFT"));
  };

  const likeClick = () => {
    if (userStore.isAuth) {
      nftStore.likeNft(nftStore.oneNFT?._id);
      const newV = liked === true ? likes - 1 : likes + 1;
      setLiked(!liked);
      setLikes(newV);
    } else toast.error("Необходимо авторизироваться");
  };
  const wishClick = () => {
    if (userStore.isAuth) {
      userStore.addTokenToWishlist(nftStore.oneNFT?._id);
      setWished(!wished);
    } else toast.error("Необходимо авторизироваться");
  };

  return (
    <div>
      {/* <Info>
        <Author
          onClick={() => useerRedirect(oneNFT?.author?.user_id)}
          style={{ marginTop: "5px" }}
        >
          <img
            style={{ marginLeft: 0 }}
         
            src={testPhome}
          />
          {t("nft::author")}
          <span>{oneNFT?.author?.fullname || "Имя автора"}</span>
        </Author>
        <Author
          style={{ marginTop: "5px" }}
          onClick={() => useerRedirect(oneNFT?.owner?.user_id)}
        >
          <img src={testPhome} />
       
          {t("nft::owner")}
          <span>{oneNFT?.owner?.fullname || "Имя владельца"}</span>
        </Author>
      </Info>
      <Info>
        {oneNFT?.coauthors?.length > 0 && (
          <Author style={{ marginTop: "5px" }}>
            {t("creation::coAutors")}:
            {oneNFT?.coauthors?.map((el, i) => {
              return (
                <span onClick={() => useerRedirect(el?.user?.user_id)} key={i}>
                  {(i !== 0 ? ", " : "") + el?.user?.fullname}
                </span>
              );
            })}
          </Author>
        )}
      </Info> */}
      <Title>
        <h1>{oneNFT?.title || "Название товара"}</h1>
        <CopyToClipboard text={`${window.location.origin + match.url}`}>
          <img
            src={require("../../../images/share.png")}
            style={{ cursor: "pointer" }}
            onMouseEnter={() => setIsvisibleShareMenu(true)}
            onMouseLeave={() => setIsvisibleShareMenu(false)}
          />
        </CopyToClipboard>
        {isvisibleShareMenu && (
          <ShareMenu
            onMouseEnter={() => setIsvisibleShareMenu(true)}
            onMouseLeave={() => setIsvisibleShareMenu(false)}
          >
            <ShareMenuContentWrapper className="border-grarient">
              <ShareMenuItem>
                <VKShareButton
                  url={window.location.href}
                  title={oneNFT?.title}
                  image={url + "/" + oneNFT?.preview}
                >
                  <VKIcon size={32} round />
                </VKShareButton>
              </ShareMenuItem>

              <ShareMenuItem>
                <WhatsappShareButton
                  url={window.location.href}
                  title={oneNFT?.title}
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </ShareMenuItem>

              <ShareMenuItem>
                <TelegramShareButton
                  url={window.location.href}
                  title={oneNFT?.title}
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
              </ShareMenuItem>
            </ShareMenuContentWrapper>
          </ShareMenu>
        )}
      </Title>
      <Info>
        <span className="token-id">
          {t("nft::tokenId")} {oneNFT?.newId}
        </span>
        <span className="nft-category">
          {eng ? transl(oneNFT?.category) : oneNFT?.category},{" "}
          {oneNFT?.hashtags}
        </span>
        {oneNFT?.collections && (
          <span
            className="token-id"
            style={{ display: "flex", alignItems: "center" }}
          >
            {t("nft::collection")}: &ensp;
            <span
              className="nft-category"
              style={{ cursor: "pointer" }}
              onClick={() =>
                collectionRedirect(
                  oneNFT?.author?.user_id,
                  oneNFT?.collections?._id
                )
              }
            >
              {" "}
              {oneNFT?.collections?.name}
            </span>
          </span>
        )}
        <span className="token-id">
          {oneNFT?.hiddenContent && `${t("nft::hiddenContent")}`}
        </span>
      </Info>

      {/* <span className="token-id">
        {eng && oneNFT?.rights
          ? transl(oneNFT?.rights)
          : !eng && oneNFT?.rights
          ? oneNFT?.rights
          : "ghjh"}
      </span> */}
      <WishLikes liked={liked} wished={wished}>
        {/* {oneNFT?.owner?._id !== userStore?.user?._id && (
          <ViewWrapper onClick={wishClick}>
            <img
              src={
                wished
                  ? require("../../../images/star-bold.png")
                  : require("../../../images/star-thin.png")
              }
              style={{
                marginLeft: "5px",
              }}
            />
          </ViewWrapper>
        )} */}
        <ViewWrapper onClick={likeClick}>
          <img
            src={
              liked
                ? require("../../../images/heart-bold.png")
                : require("../../../images/heart.png")
            }
          />
          {likes}
          {/* <FontAwesomeIcon icon={faHeart}  /> */}
        </ViewWrapper>

        <ViewWrapper>
          <img src={require("../../../images/bi_eye.png")} />
          {oneNFT?.views}
        </ViewWrapper>
      </WishLikes>
    </div>
  );
});
