import styled from "styled-components";

export interface IFilter {
  bg: string;
}
export interface IButton {
  width?: string;
  color?: string;
  violet?: boolean;
}

export const NavButton = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  position: relative;

  ${(props: {isHover: boolean})=> props.isHover ?`&:after {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    background: linear-gradient(95.33deg, #004af9 0%, #db00ff 100.88%);
  }` : ''}

  @media (max-width: 1000px) {
    display: none;
  }

`;
export const Chevron = styled.img`
  height: 4px;
  width: 7px;
  margin-left: 5px;
`;

export const Filter = styled.div`
  // background-color: #f1ebe9;
  // border: 2px solid #f1ebe9;
  position: relative;
  // backdrop-filter: blur(2px);
  // border-radius: 8px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
  padding: 0px 25px;
  margin-left: 10px;
  font-family: Releway-medium;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  // &:hover {
  //   background-color: white;
  // }
  @media (max-width: 1000px) {
    height: 30px;
    padding: 0px 15px;
    font-size: 12px;
  }
`;
export const Sort = styled.div`
  // background-color: #f1ebe9;
  // border: 2px solid #f1ebe9;
  position: relative;
  backdrop-filter: blur(2px);
  border-radius: 40px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  height: 49px;
  padding: 0px 25px;
  margin-left: 10px;
  font-family: Releway-medium;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  // &:hover {
  //   background-color: white;
  // }

  &:before {
    border-radius: 40px;
    z-index: -1;
  }
  & div {
    top: 46px;
    right: -20px;
    display: grid;
  }
  @media (max-width: 1000px) {
    height: 30px;
    padding: 0px 15px;
    font-size: 12px;
    & div {
      top: 28px;
      right: -40px;
    }
  }
`;
export const ButtonLight = styled.button`
  margin-bottom: 8px;
  width: ${(p: IButton) => (p.width ? p.width : "100%")};
  height: 40px;
  color: #fff;
  margin-top: 8px;
  font-family: Releway-semiBold;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background: none;

  background: ${(p: IButton) =>
    p.violet
      ? "linear-gradient(95.33deg, #004AF9 0%, #DB00FF 100.88%)"
      : "none"};

  & label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & a {
    text-decoration: none;
    color: #894ada;
  }
`;

export const ButtonText = styled.button`
  color: ${(p: IButton) => (p.color ? p.color : "#fff")};
  background: none;
  border: none;
`;
