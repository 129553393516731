import React from "react";
import Modal from "../components/Modal";
import { ModalsEnum, ModalStore } from "../stores/ModalStore";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { SubmitButton, TextInput, Title } from "../shared/forms/forms";
import { UserStore } from "../stores/user/UserStore";
import { toast } from "react-toastify";
import { NFTstore } from "../stores/NFTstore.";
import Web3 from "web3";
import { WithTranslation, withTranslation } from "react-i18next";
interface IConnectModalProps extends WithTranslation {
  data: {
    id: string;
    amount?: number;
  };
  idx: number;
}

interface IConnectModalState {}

@observer
class WithdrawTokenModal extends React.Component<
  IConnectModalProps,
  IConnectModalState
> {
  @resolve(ModalStore)
  protected declare readonly modalStore: ModalStore;
  @resolve(UserStore)
  protected declare readonly userStore: UserStore;
  @resolve(NFTstore)
  protected declare readonly nftStore: NFTstore;
  state = { amount: 1 };

  onChange = (e) => {
    // console.log(this.props.data.history)
    let web3 = new Web3(process.env.RPC_URL_56);
    this.setState({ price: web3.utils.toWei(e.target.value, "ether") });
  };
  onChangeAmount = (e) => {
    this.setState({ amount: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.props.data.amount < this.state.amount) {
      toast.error(this.props.t("toasts::correct"));
      return;
    }
    this.userStore
      .stopSelling(this.props.data.id, { amount: this.state.amount })
      .then((res) => {
        // console.log(res)
        toast.success(this.props.t("toasts::unSale"));
        this.modalStore.hideModal(this.props.idx);
      })
      .catch((err) => {
        //    console.log(err)
        let msg = err.response.data.err;
        err?.message && toast.error(msg);
      });
  };
  render() {
    return (
      <Modal modalKey={ModalsEnum.WithdrawToken} idx={this.props.idx}>
        <Title>{this.props.t("modals::unSale")}</Title>
        <form onSubmit={this.onSubmit}>
          {this.props.data.amount > 1 && (
            <TextInput
              title={
                this.props.t("modals::copyAmount") +
                this.props.data.amount +
                ")"
              }
              type="number"
              onChange={this.onChangeAmount}
              border
            ></TextInput>
          )}
          <SubmitButton className="border-grarient" type="submit">
            {this.props.t("modals::confirm")}
          </SubmitButton>
        </form>
      </Modal>
    );
  }
}
export default withTranslation()(WithdrawTokenModal);
