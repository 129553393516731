import { SubmitButton } from "../../../shared/forms/forms";
import { useState } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import { toast } from "react-toastify";
import { Redirect, useHistory } from "react-router";
import "./stylesNFT.scss";
import "./all.css";
import "./styles.css";
import "../profile.sass";
import { observer } from "mobx-react";
import { NFTstore } from "../../../stores/NFTstore.";
import Web3 from "web3";
import Loader from "../../../shared/loader/loader";
import { isProd } from "../../../constants";

import { useEffect } from "react";
import Select from "react-select";

import { TFunction, useTranslation } from "react-i18next";
import { useRef } from "react";

import styled from "styled-components";

import { getNewsLanguageIndex } from "./../../../utils/getNewsLanguageIndex";
import { ICategory } from "./types";
import { useLanguageContext } from "../../../context/LanguageContextProvider";

const currencies = [
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "EUR",
    label: "EUR",
  },
  {
    value: "RUB",
    label: "RUB",
  },
];

const getTypesOptions = (t: TFunction<"translation">) => {
  return [
    {
      value: "Изображение",
      label: t("creation::image"),
    },
    {
      value: "Видео",
      label: t("creation::video"),
    },
  ];
};

export const getCategories = (categories: ICategory[], lang: string) => {
  return (
    (categories &&
      categories?.map((category) => {
        const langIndex = getNewsLanguageIndex(category, lang);
        return {
          label: category?.translations[langIndex]?.name,
          value: category?._id,
        };
      })) ||
    []
  );
};

const CreateNFT = observer(() => {
  const [aboutCount, setAboutCount] = useState<number>(0);

  const userStore = useInjection(UserStore);
  const { language } = useLanguageContext();

  const nftStore = useInjection(NFTstore);
  const history = useHistory();
  const { t } = useTranslation();
  const pre = useRef(null);
  const pre2 = useRef(null);
  const file = useRef(null);
  const file2 = useRef(null);

  const categoriesOptions = getCategories(nftStore.categories, language);

  const [nftData, setNFTdata] = useState({
    title: "",
    about: "",
    price: "",
    amount: 1,
    file: null,
    preview: null,

    category: "Изображение",
    categoryId: "",
    currency: "USD",
    K1: "",
    K2: "",
    K3: "",
  });

  console.log(nftData);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    nftStore.getAllCategories();
  }, []);

  useEffect(() => {
    if (categoriesOptions && categoriesOptions.length > 0) {
      setNFTdata({ ...nftData, categoryId: categoriesOptions[0].value });
    }
  }, [nftStore.categories]);

  const onCostChange = (e) => {
    let web3 = new Web3(process.env.RPC_URL_56);

    setNFTdata({
      ...nftData,
      [e.target.name]: web3.utils.toWei(e.target.value, "ether"),
    });
  };

  const onChange = (e) => {
    if (e.target.name === "about") {
      setAboutCount(e.target.value.length);
    }
    setNFTdata({ ...nftData, [e.target.name]: e.target.value });
  };

  // /////////////////

  const fileUpload = (e) => {
    e.preventDefault();

    if (e.target.files[0]) {
      let correctSize = e.target.files[0].size < 500000000;

      if (!correctSize) {
        return toast.error(t("toasts::limit500"));
      }

      setNFTdata({ ...nftData, file: e.target.files[0] });
      toast.success(t("toasts::file"));
    }
  };
  const previewUpload = (e) => {
    e.preventDefault();

    if (e.target.files[0]) {
      let correctSize = e.target.files[0].size < 5000000;

      if (!correctSize) {
        return toast.error(t("toasts::limit5"));
      }

      setNFTdata({ ...nftData, preview: e.target.files[0] });
      toast.success(t("toasts::file"));
    }
  };

  const onSubmit = (e) => {
    const metamask = userStore.user?.metamask;

    if (!metamask) {
      toast.error(t("toasts::confirmWallet"));
      return;
    }

    e.preventDefault();
    const formDataNFT = new FormData();
    for (const [key, value] of Object.entries(nftData)) {
      formDataNFT.append(key, value);
    }

    setLoader(true);
    nftStore
      .nftCreation(formDataNFT)
      .then((res) => {
        toast.success(t("toasts::created"));
        setLoader(false);
        setTimeout(() => {
          history.goBack();
        }, 200);
      })
      .catch((err) => {
        setLoader(false);

        let msg = err.response.data.err;
        err?.message && toast.error(msg);
      });
  };

  const onRightsChange = (e) => {
    setNFTdata({ ...nftData, currency: e.value });
  };

  const onCategoryChange = (e) => {
    setNFTdata({ ...nftData, categoryId: e.value });
  };

  const onTypeChange = (e) => {
    setNFTdata({ ...nftData, category: e.value });
  };

  const typesOptions = getTypesOptions(t);

  if (isProd) {
    return <Redirect to={"/p/development"} />;
  }

  return (
    <div className="mainBlock">
      <Loader visible={loader} description={t("loaders::cretin")} />
      <form onSubmit={onSubmit}>
        <div className="lk-title">{t("creation::title")}</div>

        <div className="row">
          <div className="col-md-8">
            <div className="row justify-content-center my-3">
              <div className="col-md-10">
                <div className="input-group mb-3">
                  <span
                    className="input-group-text w-25"
                    id="token-author-desc"
                  >
                    {t("creation::author")}
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="token-author"
                    aria-describedby="token-author-desc"
                    defaultValue={userStore.user?.fullname}
                    disabled
                  />
                </div>

                <div className="input-group my-3">
                  <span className="input-group-text w-25" id="token-name-desc">
                    {t("creation::nftTitle")} *
                  </span>
                  <input
                    required
                    name="title"
                    type="text"
                    onChange={onChange}
                    className="form-control"
                    id="token-name"
                    aria-describedby="token-name-desc"
                  />
                </div>

                <div className="input-group my-3">
                  <span className="input-group-text w-25" id="token-price-fix">
                    {t("creation::buyPrice")} *
                  </span>
                  <input
                    required
                    name="price"
                    step="any"
                    type="number"
                    min={0.0001}
                    max={10000000}
                    onChange={(e) => onCostChange(e)}
                    className="form-control"
                    id="token-price-fix"
                    aria-describedby="basic-addon3"
                  />
                </div>

                <div className="input-group my-3">
                  <span className="input-group-text w-25" id="token-copies">
                    {t("creation::copies")}
                  </span>
                  <input
                    required
                    type="number"
                    step="any"
                    className="form-control"
                    name={"amount"}
                    id="token-copies"
                    onChange={onChange}
                    defaultValue={1}
                    min={1}
                    max={100}
                    aria-describedby="basic-addon3"
                  />
                </div>

                <div className="input-group mb-3">
                  <label
                    className="input-group-text w-25"
                    htmlFor="token-rights"
                  >
                    {t("creation::currency")} *
                  </label>
                  <div style={{ flexGrow: 3 }}>
                    <Select
                      defaultValue={currencies[0]}
                      // styles={selectStyles}

                      placeholder=""
                      onChange={onRightsChange}
                      options={currencies}
                    />
                  </div>
                </div>

                {nftStore.categories && (
                  <div className="input-group mb-3">
                    <label
                      className="input-group-text w-25"
                      htmlFor="token-rights"
                    >
                      {t("creation::category")} *
                    </label>
                    <div style={{ flexGrow: 3 }}>
                      <Select
                        defaultValue={categoriesOptions[0]}
                        placeholder=""
                        onChange={onCategoryChange}
                        options={categoriesOptions}
                      />
                    </div>
                  </div>
                )}
                <div className="input-group mb-3">
                  <label
                    className="input-group-text w-25"
                    htmlFor="token-rights"
                  >
                    {t("creation::type")} *
                  </label>
                  <div style={{ flexGrow: 3 }}>
                    <Select
                      defaultValue={typesOptions[0]}
                      placeholder=""
                      onChange={onTypeChange}
                      options={typesOptions}
                    />
                  </div>
                </div>

                <div className="input-group my-3">
                  <span className="input-group-text w-25" id="token-name-desc">
                    {"K1"} *
                  </span>
                  <input
                    required
                    name="K1"
                    type="text"
                    onChange={onChange}
                    className="form-control"
                    id="token-name"
                    aria-describedby="token-name-desc"
                  />
                </div>

                <div className="input-group my-3">
                  <span className="input-group-text w-25" id="token-name-desc">
                    {"K2"} *
                  </span>
                  <input
                    required
                    name="K2"
                    type="text"
                    onChange={onChange}
                    className="form-control"
                    id="token-name"
                    aria-describedby="token-name-desc"
                  />
                </div>

                <div className="input-group my-3">
                  <span className="input-group-text w-25" id="token-name-desc">
                    {"K3"} *
                  </span>
                  <input
                    required
                    name="K3"
                    type="text"
                    onChange={onChange}
                    className="form-control"
                    id="token-name"
                    aria-describedby="token-name-desc"
                  />
                </div>
              </div>
            </div>

            <hr />
            <div className="row justify-content-center my-3">
              <div className="col-md-10">
                <h3>{t("creation::objDescription")}</h3>
                <div className="form-floating">
                  <textarea
                    name="about"
                    onChange={onChange}
                    className="form-control"
                    placeholder="Введите описание токена"
                    id="token-description"
                    style={{ height: "200px" }}
                    maxLength={1000}
                    defaultValue={""}
                  />
                  <label
                    style={{ display: aboutCount > 0 ? "none" : "block" }}
                    htmlFor="token-description"
                  >
                    {t("creation::objDescriptionInside")}
                  </label>
                </div>
                <small>{aboutCount + t("creation::objDescriptionMax")}</small>
              </div>
            </div>
            <hr />
            <div className="row justify-content-center my-3">
              <div className="col-md-10">
                <h3>{t("creation::preview")}</h3>
                <p>{t("creation::pnote")}</p>
                <div
                  className="file-drop-area"
                  style={{ backgroundColor: nftData.preview ? "#DAF9D2" : "" }}
                >
                  <div
                    style={{ position: "absolute", top: "20px", left: "50px" }}
                  >
                    {nftData.preview?.name}
                  </div>
                  {!nftData.preview ? (
                    <>
                      <span className="fake-btn">{t("creation::choose")}</span>
                      <span className="file-msg">{t("creation::drag")}</span>
                      <input
                        required
                        ref={pre}
                        onChange={previewUpload}
                        className="file-input"
                        type="file"
                      />
                    </>
                  ) : (
                    <>
                      <span className="fake-btn">
                        {t("creation::chooseAnother")}
                      </span>
                      <span className="file-msg">{t("creation::drag")}</span>
                      <input
                        required
                        ref={pre2}
                        onChange={previewUpload}
                        className="file-input"
                        type="file"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <hr />
            <div className="row justify-content-center my-3 ">
              <div className="col-md-10">
                <h3>{t("creation::mainFile")}</h3>
                <p>{t("creation::fnote")}</p>
                <div
                  className="file-drop-area"
                  style={{ backgroundColor: nftData.file ? "#DAF9D2" : "" }}
                >
                  <div
                    style={{ position: "absolute", top: "20px", left: "50px" }}
                  >
                    {nftData.file?.name}
                  </div>
                  {!nftData.file ? (
                    <>
                      <span className="fake-btn">{t("creation::choose")}</span>
                      <span className="file-msg">{t("creation::drag")}</span>
                      <input
                        required
                        ref={file}
                        onChange={fileUpload}
                        className="file-input"
                        type="file"
                      />
                    </>
                  ) : (
                    <>
                      <span className="fake-btn">
                        {t("creation::chooseAnother")}
                      </span>
                      <span className="file-msg">{t("creation::drag")}</span>
                      <input
                        required
                        ref={file2}
                        onChange={fileUpload}
                        className="file-input"
                        type="file"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-center text-center my-3 ">
              <div className="col-6">
                <SubmitButton
                  type="submit"
                  className="frm_button border-grarient"
                >
                  {t("creation::createNftButton")}
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
});

export default CreateNFT;

// let imgWidth = 0
// let imgHeight = 0
// var _URL = window.URL || window.webkitURL;
// var file = e.target.files[0]
// var img = new Image();
// var objectUrl = _URL.createObjectURL(file);
// img.onload = function () {
//     imgWidth = this.width
//     imgHeight = this.height
//     _URL.revokeObjectURL(objectUrl);
// };
// img.src = objectUrl;

const DP = styled.span`
  width: 50%;
  input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
`;
