import { observer } from "mobx-react";
import "./nft.sass";
import { useEffect, useState } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { abi as CONFIRM_ABI } from "../../contracts/artifact.json";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { NFTstore } from "../../stores/NFTstore.";
import Web3 from "web3";
import Loader from "../../shared/loader/loader";
import { isProd } from "../../constants";
import { useTranslation } from "react-i18next";
import { LeftCol } from "./components/leftColumn";
import { BasicInfo } from "./components/basicInfo";
import { Auction } from "./components/auc";
import { BottomMenu } from "./components/bottomMenu";
import { SimilarNFT } from "./components/similarNft";
import { SimpleBuy } from "./components/simpleBuy";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";
import { useWeb3React } from "@web3-react/core";
import { useWeb3Contract } from "./../../hooks/web3";

export const Path = process.env.REACT_APP_IP;
export const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];
export const getDateAuc = (aucEnd) => {
  let date = new Date(aucEnd);
  return (
    months[date.getMonth()] +
    " " +
    date.getDate() +
    ", " +
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
    ":" +
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())
  );
};
export const Author = styled.span`
  cursor: pointer;
  flex-grow: 3;
  & img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin: 0 11px;
  }

  & span {
    font-family: Releway-regular;
    color: #894ada;
    font-size: 14px;
    margin-left: 5px;
  }
`;
export const Title = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 15px 0;
  color: #fff;
  & h1 {
    font-family: Releway-bold;
    font-size: 36px;
    margin: 0;
    @media screen and (max-width: 1000px) {
      font-size: 22px;
    }
  }
`;
export const Info = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
`;
export const Price = styled.div`
  margin-top: 25px;
  font-size: 26px;
  color: #fff;
  & b {
    font-family: Releway-bold;
    font-size: 36px;

    margin-right: 5px;
    @media screen and (max-width: 1000px) {
      font-size: 20px;
    }
  }
  & p {
    margin: 0;
    font-size: 14px;

    @media screen and (max-width: 1000px) {
      font-size: 12px;
    }

    & span {
      margin-left: 10px;
      height: 20px;
    }
  }
`;

export const Description = styled.div`
  font-family: Releway-regular;
  font-size: 24px;
  margin-top: 40px;
  color: #fff;

  & h2 {
    font-family: Releway-semiBold;
    margin: 0;
    font-size: 36px;
    margin: 0 0 15px 0;
  }
`;
export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  margin-bottom: 30px;
  & button {
    width: 250px;
    margin-right: 20px;
    /* background-color: lightgray; */
    /* color: gray; */
    /* cursor: default; */
  }
  & svg {
    /* color: white; */
    cursor: pointer;
  }
`;

const ButtonBack = styled.div`
  width: 31px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.05);
  margin-bottom: 42px;
  cursor: pointer;

  & img {
    width: 6px;
    height: 11.5px;
  }
`;

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 64px;
`;

interface origCopy {
  owner: any;
  isSelling: boolean;
  price: number;
}

interface IAuc {
  allBids: [];
  currentBid: any;
  currentPrice: number;
  date: number;
  dateEnd: string;
  fullPrice: number;
  open: boolean;
  startPrice: number;
}

const Nft = observer(({ match }) => {
  const nftStore = useInjection(NFTstore);
  const userStore = useInjection(UserStore);
  const history = useHistory();
  const { t } = useTranslation();
  const [isAuc, setAuc] = useState(true);
  const [hidden, setHidden] = useState(false);
  const [stop, setStop] = useState(false);
  const [auc, setAucState] = useState<IAuc>();
  const [loader, setLoader] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [copiesLeft, setCopiesLeft] = useState(0);
  const [allCopies, setAllCopies] = useState(0);
  const [authorOffer, setAuthorOffer] = useState([]);
  const [ownedCopies, setOwnedCopies] = useState<origCopy[]>([]);
  const [origCopies, setOrigCopies] = useState<origCopy[]>([]);
  const [queryCount, setCount] = useState(0);
  let web3 = new Web3(process.env.RPC_URL_56);
  const { ethereum } = window as any;
  const { account } = useWeb3React();
  const confirmContact = useWeb3Contract(CONFIRM_ABI);

  const onSuccess = (res) => {
    if (res.msg === "STOP") {
      setStop(true);
    }
    console.log(res);
    if (res.msg === "BOUGHT") {
      setStop(false);
      history.push("../../../p/catalog");
      toast.success(t("toasts::lot"));
    }
    if (res.msg === "NEW_BID") {
      setStop(false);
      setAucState(res.auction);
      toast.success(t("toasts::bet"));
    }
    if (res.msg === "CONTINUE") {
      setStop(false);
      toast.error(t("toasts::error"));
    }
  };

  useEffect(() => {
    if (nftStore.oneNFT?.first === userStore.user?._id) {
      setHidden(true);
    }
  }, [userStore.user]);
  useEffect(() => {
    return () => {
      nftStore.socket.off(nftStore.oneNFT._id, onSuccess);
      console.log("no connection");
      nftStore.clearNFTState();
    };
  }, []);

  useEffect(() => {
    nftStore.getOneNFT(match.params.id).then((res) => {
      let now = new Date();
      let token = "viewtoken" + nftStore.oneNFT._id;
      if (
        !localStorage.getItem(token) ||
        parseInt(localStorage.getItem(token)) < Date.parse(now.toString())
      ) {
        let ttl: number = 60000;
        Date.parse(now.toString()) + ttl;
        localStorage.setItem(token, `${Date.parse(now.toString()) + ttl}`);
        nftStore.increaseUsersCount(nftStore.oneNFT._id);
      }
      nftStore.socket.on(nftStore.oneNFT._id, onSuccess);
      if (nftStore.oneNFT.auction?.startPrice) {
        setAuc(true);
        setAucState(nftStore.oneNFT.auction);
      }
      if (!nftStore.oneNFT.auction?.open && nftStore.oneNFT?.isSelling) {
        setAuc(false);
      }
      if (
        nftStore?.oneNFT?.instances?.length > 1 &&
        nftStore?.oneNFT?.instances
      ) {
        nftStore?.oneNFT?.instances?.map((el) => {
          setOwnedCopies((ownedCopies) => [
            ...ownedCopies,
            {
              owner: el.owner,
              isSelling: el.isSelling,
              price: el.price,
            },
          ]);
        });
        setIsCopied(true);
        setCopiesLeft(
          nftStore?.oneNFT?.instances?.filter((copy) => copy?.isSelling).length
        );
        setAllCopies(nftStore?.oneNFT?.instances?.length);
      }
    });
  }, [match.params.id, queryCount]);
  useEffect(() => {
    // console.log('asdasd')
    if (ownedCopies.length === nftStore?.oneNFT?.instances?.length) {
      const uniqBy = (copies, key) => {
        let seen = {};
        // console.log(copies)
        return copies.filter((item) => {
          let k = key(item);
          return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        });
      };
      setOrigCopies(uniqBy(ownedCopies, JSON.stringify));
      let autorFilter = nftStore?.oneNFT?.instances.filter(
        (el) =>
          el.owner.user_id === nftStore?.oneNFT?.author.user_id && el.isSelling
      );
      setAuthorOffer([autorFilter[autorFilter.length - 1]]);
    }
  }, [ownedCopies]);

  useEffect(() => {
    if (!nftStore?.NFT) {
      nftStore.getNFTcatalog();
    }
  }, []);

  const BuyNFT = async (id, price) => {
    if (!userStore.isAuth) toast.error("Необходимо авторизоваться для покупки");
    else {
      try {
        await ethereum.enable();
        setLoader(true);
        let web3 = new Web3(ethereum);
        web3.eth.defaultAccount = account;
        const contract = await confirmContact(process.env.MINT_CONTRACT)
          .methods.buy(id)
          .encodeABI();

        const gasEstimate = await confirmContact(process.env.MINT_CONTRACT)
          .methods.buy(id)
          .estimateGas({
            to: process.env.MINT_CONTRACT,
            from: web3.eth.defaultAccount,
            value: price,
          });

        const gasPrice = await web3.eth.getGasPrice();

        let tx = {
          to: process.env.MINT_CONTRACT,
          from: web3.eth.defaultAccount,
          data: contract,
          value: price,
          gasPrice,
          gas: gasEstimate,
        };

        web3.eth.sendTransaction(tx, function (error, hash) {
          if (error) {
            toast.error(t("toasts::buyError"));
            setLoader(false);
          } else {
            nftStore
              .buyNFT(match.params.id, userStore?.user?._id)
              .then((res) => {
                setLoader(false);
                setCount(queryCount + 1);
                toast.success(t("toasts::buyCompleted"));
              })
              .catch((err) => {
                setLoader(false);
                setCount(queryCount + 1);
                let msg = err.response.data.err;
                err?.message && toast.error(msg);
              });
          }
        });
      } catch (err) {
        toast.error(t("toasts::nofunds"));
        setLoader(false);
      }
    }
  };

  console.log(window.location.href);

  if (isProd) {
    return <Redirect to={"/p/development"} />;
  }
  return (
    <SmoothAppearance trigger={nftStore.oneNFT}>
      <Wrapper>
        <div className="main-container">
          <ButtonBack onClick={() => history.goBack()}>
            <img
              src={require("./../../images/icons/arrow-left.png")}
              alt="arrow"
            />
          </ButtonBack>
          <div className="nft-container">
            <Loader
              visible={loader}
              description={`${t("loader::purchaseInProg")}`}
            />

            <LeftCol oneNFT={nftStore.oneNFT}></LeftCol>
            <div className="nft-data">
              <BasicInfo
                oneNFT={nftStore.oneNFT}
                history={history}
                match={match}
              ></BasicInfo>

              <SimpleBuy
                oneNFT={nftStore.oneNFT}
                web3={web3}
                BuyNFT={BuyNFT}
                authorOffer={authorOffer}
                stop={stop}
                isCopied={isCopied}
                origCopies={origCopies}
                copiesLeft={copiesLeft}
                allCopies={allCopies}
              />

              {/* <BottomMenu
            hidden={hidden}
            isAuc={isAuc}
            oneNFT={nftStore.oneNFT}
            allSells={nftStore?.oneNFT?.transfers}
            allBids={auc?.allBids}
            isOpen={auc?.open}
          ></BottomMenu> */}
            </div>
          </div>
          <Description className="ntf-desctop-description">
            <h2>{t("nft::description")}</h2>
            {nftStore?.oneNFT?.about}
          </Description>

          <SimilarNFT NFT={nftStore?.NFT}></SimilarNFT>
        </div>
      </Wrapper>
    </SmoothAppearance>
  );
});

export default Nft;
