import React, { useEffect } from "react";
import { useInjection } from "inversify-react";
import { NFTstore } from "./../stores/NFTstore.";

export const RouterContext = React.createContext({});

export const useCRouterContext = () => {
  const context = React.useContext(RouterContext);
  if (!context) {
    throw new Error(
      "useRouterContext must be used within a RouterContextProvider"
    );
  }
  return context;
};

export function RouterContextProvider({ children }) {
  const nftStore = useInjection(NFTstore);

  useEffect(() => {
    nftStore.getAllAdditionalPages();
  }, []);

  return <RouterContext.Provider value={{}}>{children}</RouterContext.Provider>;
}
