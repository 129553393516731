import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { ModalsEnum, ModalStore } from "../stores/ModalStore";
import { useInjection } from "inversify-react";
import styled from "styled-components";
import "./Modal.styl";

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0008;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
`;
const ModalStyle = styled.div`
  width: 400px;
  min-width: 200px;

  padding: 20px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
`;
interface IModalProps {
  modalKey: ModalsEnum;
  onShow?: () => any;
  onHide?: () => any;
  closable?: boolean;
  idx: number;
  nopadding?: boolean;
}

type P = React.PropsWithChildren<IModalProps>;

const Modal: React.FC<P> = observer(
  ({
    modalKey,
    children,
    onShow,
    onHide,
    idx,
    nopadding,
    closable = true,
  }: P) => {
    const fade = useRef();

    const modalStore = useInjection(ModalStore);

    useEffect(() => {
      onShow?.();
      return () => onHide?.();
    }, []);

    return (
      <Background
        ref={fade}
        onClick={(e) =>
          e.target === fade.current && closable && modalStore.hideModal(idx)
        }
      >
        <ModalStyle style={{ padding: nopadding ? "0px" : "20px" }}>
          {children}
        </ModalStyle>
      </Background>
    );
  }
);

export default Modal;
