import { SmoothAppearance } from "./../../shared/layout/smoothAppearance";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ReactStars from "react-rating-stars-component";

import { useEffect, useState } from "react";
import { SubmitButton } from "./../../shared/forms/forms";
import { Formik } from "formik";
import { reviewsSchema } from "./../../utils/validation";
import { toast } from "react-toastify";
import { useInjection } from "inversify-react";
import { UserStore } from "./../../stores/user/UserStore";
import { observer } from "mobx-react-lite";

const Page = styled.div`
  padding: 50px 0;
  color: #fff;
`;
const Container = styled.div``;
const PageTitle = styled.h2`
  text-tranform: uppercase;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 70px;
`;

export interface IReview {
  type: string;
  name: string;
  _id: string;
  grade: number;
  whatLike: string;
  whatNotLike: string;
  comment: string;
}

const ReviewsPage = observer(() => {
  const { t } = useTranslation();
  const userStore = useInjection(UserStore);

  const [rating, setRating] = useState(3);
  const [isRiview, setIsreview] = useState(false);

  const ratingChanged = (newRating: number) => {
    setRating(newRating);
  };

  const onSubmit = (values, { resetForm }) => {
    const formdata = {
      grade: rating,
      name: values.name,
      comment: values.comment,
      whatLike: values.pros,
      whatNotLike: values.cons,
    };

    userStore.createReview(formdata).then(() => resetForm());
  };

  useEffect(() => {
    userStore.getAllReviews();
  }, []);

  const reviews = userStore.reviews;

  return (
    <SmoothAppearance trigger={userStore.reviews}>
      <Page>
        <Container className="main-container">
          <PageTitle> {t("reviews::title")}</PageTitle>

          <ReviewsWrapper>
            <ReviewsList>
              {reviews?.map((review, index) => (
                <ReviewDetail review={review} key={index} />
              ))}
            </ReviewsList>

            {/* Form */}
            {!isRiview ? (
              <ReviewsButton
                className="custom-bg-gradient"
                onClick={() => {
                  if (userStore.isAuth) {
                    setIsreview(true);
                  } else {
                    toast.error("Необходимо авторизоваться");
                  }
                }}
              >
                {t("reviews::leave-feedback")}
              </ReviewsButton>
            ) : (
              <ReviewFormWrapper>
                <span style={{ fontSize: "28px", fontWeight: 500 }}>
                  {t("reviews::leave-feedback")}
                </span>

                <ReviewsProductFormRating>
                  <div>{t("reviews::your-mark")}</div>
                  <ReviewsProductFormRatingwrapper
                    style={{ marginTop: "11px" }}
                  >
                    <ReactStars
                      size={35}
                      color={"#EDF2F6"}
                      onChange={ratingChanged}
                      value={rating}
                      edit={true}
                    />
                  </ReviewsProductFormRatingwrapper>
                </ReviewsProductFormRating>

                <Formik
                  initialValues={{
                    name: "",
                    comment: "",
                    pros: "",
                    cons: "",
                  }}
                  onSubmit={onSubmit}
                  validationSchema={reviewsSchema}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <div>
                      <ReviewsLabelTitle>
                        <label htmlFor="name">{t("reviews::name")} *</label>
                        <br />
                        <ReviewsInputName
                          type="text"
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                          placeholder={t("reviews::name-placeholder")}
                        />
                      </ReviewsLabelTitle>
                      {errors.name && (
                        <div style={{ position: "relative" }}>
                          <ReviewsLabelError>{errors.name}</ReviewsLabelError>
                        </div>
                      )}

                      <ReviewsLabelTitle>
                        <label htmlFor="comment">
                          {t("reviews::what-like")}
                        </label>
                        <br />
                        <ReviewsInputComment
                          name="pros"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.pros}
                          placeholder={t("reviews::what-like-placeholder")}
                        />
                      </ReviewsLabelTitle>
                      {errors.pros && (
                        <div style={{ position: "relative" }}>
                          <ReviewsLabelError>{errors.pros}</ReviewsLabelError>
                        </div>
                      )}

                      <ReviewsLabelTitle>
                        <label htmlFor="comment">
                          {t("reviews::what-no-like")}
                        </label>
                        <br />
                        <ReviewsInputComment
                          name="cons"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.cons}
                          placeholder={t("reviews::what-no-like-placeholder")}
                        />
                      </ReviewsLabelTitle>
                      {errors.cons && (
                        <div style={{ position: "relative" }}>
                          <ReviewsLabelError>{errors.cons}</ReviewsLabelError>
                        </div>
                      )}

                      <ReviewsLabelTitle>
                        <label htmlFor="comment">
                          {t("reviews::comment")} *
                        </label>
                        <br />
                        <ReviewsInputComment
                          name="comment"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.comment}
                          placeholder={t("reviews::comment-placeholder")}
                        />
                      </ReviewsLabelTitle>
                      {errors.comment && (
                        <div style={{ position: "relative" }}>
                          <ReviewsLabelError>
                            {errors.comment}
                          </ReviewsLabelError>
                        </div>
                      )}

                      <ReviewsButton
                        className="custom-bg-gradient"
                        onClick={() => handleSubmit()}
                        type="submit"
                      >
                        {t("reviews::submit")} *
                      </ReviewsButton>
                    </div>
                  )}
                </Formik>
              </ReviewFormWrapper>
            )}
          </ReviewsWrapper>
        </Container>
      </Page>
    </SmoothAppearance>
  );
});

export default ReviewsPage;

interface IParams {
  review: IReview;
}

export const ReviewDetail: React.FC<IParams> = ({ review }) => {
  return (
    <ReviewsListItem className="border-grarient">
      <ReviewsListItemWrapper>
        <ReviewsListItemName>{review?.name}</ReviewsListItemName>
        <ReactStars
          size={24}
          color={"#EDF2F6"}
          edit={false}
          value={review?.grade || 5}
        />

        <LikesWrapper>
          <img
            src={
              review?.type === "negative"
                ? require("./../../images/dislike-button.png")
                : require("./../../images/like-button.png")
            }
            alt={review?.type === "negative" ? "dislike" : "like"}
          />
        </LikesWrapper>
      </ReviewsListItemWrapper>
      <ReviewsListItemComment>
        <span>Понравилось:</span> {review?.whatLike}
      </ReviewsListItemComment>
      <ReviewsListItemComment>
        <span>Не понравилось:</span> {review?.whatNotLike}
      </ReviewsListItemComment>
      <ReviewsListItemComment>
        <span>Коментарий:</span> {review?.comment}
      </ReviewsListItemComment>
    </ReviewsListItem>
  );
};

const ReviewsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1340px) {
    flex-direction: column;
  }
`;

const ReviewsList = styled.div`
  width: 48%;

  @media (max-width: 1340px) {
    width: 100%;
  }
`;

const ReviewsListItem = styled.div`
  width: 100%;
  margin-top: 50px;
  padding: 30px;

  &:first-child {
    margin-top: 0;
  }
`;

const ReviewsListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 20px;
`;

const ReviewFormWrapper = styled.div`
  width: 48%;
  @media (max-width: 1340px) {
    width: 100%;
    margin-top: 50px;
  }
`;

const ReviewsListItemName = styled.div`
  font-size: 26px;
  font-weight: 500;
  margin-right: 14px;
`;

const LikesWrapper = styled.div`
  display: flex;
  margin-left: 20px;

  & img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    display: block;
    cursor: pointer;
  }
`;

const ReviewsListItemComment = styled.div`
  font-size: 16px;
  margin-top: 12px;
  line-height: 1.5em;
  margin-bottom: 24px;

  & span {
    font-weight: 700;
    font-size: 18px;
  }
`;

const ReviewsProductFormRatingwrapper = styled.div`
  display: flex;
`;

const ReviewsProductFormRating = styled.div`
  margin-top: 24px;
  margin-right: 30px;
`;

const ReviewsLabelTitle = styled.p`
  margin-top: 24px;
`;

const ReviewsLabelError = styled.p`
  color: red;
  position: absolute;
  left: 0;
  top: -13px;
`;

const ReviewsInputName = styled.input`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #edf2f6;
  margin-top: 10px;
  padding: 16px 16px 16px 24px;
  font-size: 16px;
  ::-webkit-input-placeholder {
    color: #b2bad6;
  }
`;

const ReviewsInputEmail = styled.input`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #edf2f6;
  margin-top: 10px;
  padding: 16px 16px 16px 24px;
  font-size: 16px;
  ::-webkit-input-placeholder {
    color: #b2bad6;
  }
`;

const ReviewsInputComment = styled.textarea`
  resize: none;
  width: 100%;
  height: 168px;
  border-radius: 8px;
  border: 1px solid #edf2f6;
  margin-top: 10px;
  padding: 16px;
  font-size: 16px;
  text-align: start;
  ::-webkit-input-placeholder {
    color: #b2bad6;
  }
`;

const ReviewsButton = styled.button`
  width: 250px;
  height: 56px;
  color: #ffffff;
  font-size: 16px;
  weight: 500;

  margin-top: 24px;
  cursor: pointer;
`;
