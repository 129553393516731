import axios from "axios";
import { injectable } from "inversify";
import { action, makeObservable, observable } from "mobx";
import "reflect-metadata";
import { innerBackend, setAuthToken, url } from "../../components/auth/helper";
import { RootStore } from "../RootStore";
import { toast } from "react-toastify";

@injectable()
export class UsersStore {
  @observable user?: RegisterData;
  // @observable allUsers: any = [];
  @observable usersQuery: any = [];

  public constructor(private readonly rootStore: RootStore) {
    makeObservable(this);
  }

  //@POST /users/auth {email:email,password:password} - авторизация
  //его в POST /usman/validate/:id
  //POST /usman/auth {password,field,content}
  //филд - email или public_key
  //POST /usman/auth {password,field,content}
  // async getAllUsers(){
  //   try {
  //     const res = await axios.get(`${url}/user/getall`);
  //     return this.allUsers = res.data
  //   } catch (err){
  //     let msg = err.response.data.err
  //     err?.message && toast.error(msg)
  //     return false
  //   }
  // }
  async getOneUser(user_id) {
    console.log("get one user", user_id.split("/"));
    try {
      if (user_id) {
        const res = await axios.get(
          `${url}/user/get/${encodeURIComponent(user_id)}`
        );
        if (res.data) {
          this.user = res.data;
        }

        // console.log(res.data)
        return true;
      }
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
      return false;
    }
  }
  async getUsersQuery(text, myId) {
    try {
      if (text) {
        const res = await axios.get(`${url}/user/get/query?value=${text}`);
        if (res.data) {
          const us = res.data.map((el, i) => {
            if (el._id !== myId) {
              return { value: el.user_id, label: el.fullname };
            } else return { value: "", label: "" };
          });
          this.usersQuery = us;
        }
        return true;
      }
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
      return false;
    }
  }

  async clearUser() {
    this.user = null;
  }
}

export interface LoginData {
  password: string;
  field?: string;
  value: string; //email / public key
}

export interface RegisterData {
  public_key?: string;
  user_type?: string;
  photo?: string;
  fullname?: string;
  phone?: string;
  referals?: any;
  email?: string;
  password?: string;
  user_id?: string;
  referal_code?: string;
  balance?: string;
  referal?: string;
  about?: string;
  education?: string;
  social?: string;
  null?: null;
  works?: any;
  banner?: string;
  _id: string;
}
