import { SmoothAppearance } from "../../../shared/layout/smoothAppearance";
import styled from "styled-components";
import "react-quill/dist/quill.snow.css";
import { useInjection } from "inversify-react";
import { NFTstore } from "../../../stores/NFTstore.";
import { UserStore } from "../../../stores/user/UserStore";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { getDefaultDateFormat } from "./../../../utils/getDefaultDateFormat";
import { observer } from "mobx-react";
import { getNewsLanguageIndex } from "./../../../utils/getNewsLanguageIndex";
import { useLanguageContext } from "../../../context/LanguageContextProvider";

const News = styled.div`
  padding: 50px 0;
  color: #fff;
`;
const Container = styled.div`
  max-width: 920px;
  margin: 0 auto;

  @media (max-width: 950px) {
    max-width: 90%;
  }
`;
const NewsTitle = styled.h2`
  text-tranform: uppercase;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 15px;
`;
const NewsDate = styled.h2`
  text-align: center;
  font-size: 16px;
  color: #7a7a7a;
  margin-bottom: 20px;
`;

const NewsContent = styled.p`
  font-size: 16px;
`;

const NewsContentWrapper = styled.div`
  border: none !important;
`;

interface IParams {
  id: string;
}

const NewsDetaill = observer(() => {
  const nftStore = useInjection(NFTstore);
  const userStore = useInjection(UserStore);
  const { id } = useParams<IParams>();
  const { language } = useLanguageContext();

  const newsDetail = nftStore.newsDetail;
  const langIndex = getNewsLanguageIndex(newsDetail, language);

  useEffect(() => {
    nftStore.getNewsDetail(id);
  }, []);

  useEffect(() => {
    if (nftStore.newsDetail) {
      const tagsValue = `<title>${nftStore.newsDetail?.translations[langIndex]?.metaTite}</title><meta name="description" content="${nftStore.newsDetail?.translations[langIndex]?.metaDescription}" />`;
      userStore.setSeo(tagsValue);
    }
  }, [nftStore.newsDetail, language, id]);

  return (
    <SmoothAppearance trigger={newsDetail}>
      <News>
        <Container>
          <NewsTitle>{newsDetail?.translations[langIndex]?.title}</NewsTitle>
          {newsDetail?.createdAt && (
            <NewsDate>{getDefaultDateFormat(newsDetail?.createdAt)}</NewsDate>
          )}
          <NewsContentWrapper className="ql-container ql-snow">
            <NewsContent
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: newsDetail?.translations[langIndex]?.text,
              }}
            />
          </NewsContentWrapper>
        </Container>
      </News>
    </SmoothAppearance>
  );
});

export default NewsDetaill;
