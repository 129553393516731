import { observer } from "mobx-react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";

import { BG, Container, Title } from "../../../shared/forms/forms";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { innerBackend, url } from "./../../../components/auth/helper";

const ButtonWhite = styled.button`
  height: 50px;
  border-radius: 8px;
  border: none;
  font-family: Releway-semiBold;
  color: #fff;
  width: 100%;
  margin-top: 16px;
  background: none;

  &:before {
    border-radius: 8px;
  }

  position: relative;
`;

const GreetingForm = observer(() => {
  const store = useInjection(UserStore);
  const { t } = useTranslation();

  const connectToMetaMask = async () => {
    const { ethereum } = window as any;
    if (!ethereum) return console.error("metamask is not install");
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });

    const account = accounts[0];
    const messageResponce = await innerBackend.post(
      url + "/auth/metamask/message",
      {
        account,
      }
    );
    const message = messageResponce.data.message;
    const signature = await ethereum.request({
      method: "personal_sign",
      params: [message, account],
    });
    await innerBackend
      .post(url + "/auth/metamask", { message, signature })
      .then((res) => res.data.token)
      .then((token) => {
        store.MetaLogin(token);
      });
  };

  return (
    <BG>
      <Container className="border-grarient">
        <Link to={"/"}>
          <img
            className="modalCross"
            src={require("../../../images/modal-cross.png")}
          />
        </Link>
        <Title>{t("form::authIn")}</Title>
        <p style={{ textAlign: "center", color: "#fff" }}>
          {" "}
          {t("form::logIn")} <br /> {t("form::connectWallet")}
        </p>
        <NavLink to="auth/signup">
          <ButtonWhite className="border-grarient">
            {t("button::registrate")}
          </ButtonWhite>
        </NavLink>

        <NavLink to="auth/login">
          <ButtonWhite className="border-grarient">
            {t("button::logToSystem")}
          </ButtonWhite>
        </NavLink>

        <ButtonWhite className="border-grarient" onClick={connectToMetaMask}>
          {t("button::logMetaMask")}
        </ButtonWhite>
      </Container>
    </BG>
  );
});

export default GreetingForm;
