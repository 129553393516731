import { IProfileRoute } from "./../pages/profile/lk/dashboard";

export interface ProfileChapter {
  path: string;
  isVisible: boolean;
}

export const getVisibleProfileRoutes = (
  serverNav: ProfileChapter[] = [],
  routesInfo: IProfileRoute[]
) => {
  let result = [];
  const visibleNav = serverNav
    .filter((item) => item.isVisible)
    .map((nav) => nav.path);

  routesInfo.forEach((route) => {
    if (visibleNav.includes(route.path)) {
      result.push(route);
    }
  });

  return result;
};
