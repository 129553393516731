import { INavItem } from "./constants";
import { ProfileChapter } from "./getVisibleProfileRoutes";

export const getProfileNavValue = (
  serverNav: ProfileChapter[] = [],
  profileNav: INavItem[]
) => {
  let result = [];
  const visibleNav = serverNav
    .filter((item) => item.isVisible)
    .map((nav) => nav.path);

  const isMyNFT = visibleNav.includes("");
  const isCreate = visibleNav.includes("created");
  const isCollections = visibleNav.includes("collections");
  const isWishlist = visibleNav.includes("wishlist");
  const isBlog = visibleNav.includes("blog");
  const is3D = visibleNav.includes("3d");
  const isProfile = visibleNav.includes("profile");
  const isReferals = visibleNav.includes("referals");
  const isWallet = visibleNav.includes("wallet");

  const isHasNFTChapter =
    !isMyNFT && !isCreate && !isCollections && !isWishlist;
  const isHasSocial = !isBlog && !is3D;
  const isHasControl = !isProfile && !isReferals && !isWallet;

  const validateTitle = (title: string) => {
    const validate =
      title === "sidebar::nft"
        ? isHasNFTChapter
        : title === "sidebar::social"
        ? isHasSocial
        : isHasControl;

    if (title && validate) {
      return false;
    }
    return true;
  };

  profileNav.forEach((nav) => {
    if (nav.title) {
      const validate = validateTitle(nav.title);

      validate && result.push(nav);
    } else {
      if (visibleNav.includes(nav.path)) {
        result.push(nav);
      }
    }
  });

  return result;
};
