import { useTranslation } from "react-i18next";
import CatalogItem from "../../../pages/Catalog/catalogItem";
import { Description } from "..";
import { observer } from "mobx-react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 60px 35px;
  margin-top: 70px;
`;

export const SimilarNFT = observer(({ NFT }) => {
  const { t } = useTranslation();
  if (!NFT) return <></>;

  return (
    <Wrapper className="border-grarient">
      <div className="nft-same">
        {NFT && (
          <Description
            style={{ marginTop: 0, textAlign: "center", marginBottom: "80px" }}
          >
            <h2>{t("nft::similar")}</h2>
          </Description>
        )}
        <div className="nft-grid">
          {NFT?.map((el, i) => {
            if (i < 6) {
              return <CatalogItem key={"same" + i} el={el} />;
            }
          })}
        </div>
      </div>
    </Wrapper>
  );
});
