// import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWeb3React } from "@web3-react/core";
import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { injected } from "./../../../hooks/connectors";
import { SubmitButton } from "../../../shared/forms/forms";
import { UserStore } from "../../../stores/user/UserStore";
import { Buttons, Price } from "../index";

export const SimpleBuy = observer(
  ({
    oneNFT,
    origCopies,
    authorOffer,
    web3,
    BuyNFT,
    stop,
    isCopied,
    copiesLeft,
    allCopies,
  }) => {
    const { t, i18n } = useTranslation();
    const userStore = useInjection(UserStore);
    const [price, setPrice] = useState<number>(0);
    const [border, setBorder] = useState<number>(0);
    const [currentBuyId, setCurrentBuyId] = useState<string>("");
    const [copyOwner, setCopyOwner] = useState<string>("");

    const { activate, account } = useWeb3React();
    const { ethereum } = window as any;

    useEffect(() => {
      if (isCopied) {
        setPrice(oneNFT?.price);
      }
      setCurrentBuyId(oneNFT?._id);
    }, [isCopied]);
    useEffect(() => {
      setCurrentBuyId(oneNFT?._id);
      setPrice(oneNFT?.price);
    }, [oneNFT]);
    const newPrice = (el, i) => {
      setPrice(el.price);
      // console.log(el, oneNFT.instances.find(ins=>ins?.owner?.fullname==el?.owner?.fullname&&ins?.price===el?.price))
      setCurrentBuyId(
        oneNFT.instances.find(
          (ins) =>
            ins?.owner?.fullname == el?.owner?.fullname &&
            ins?.price === el?.price
        )?._id
      );
      setBorder(i);
      setCopyOwner(el.owner?.user_id);
    };

    // const isError = async () => {
    //   await ethereum?.request({
    //     method: "wallet_addEthereumChain",
    //     params: [
    //       {
    //         chainId: "0x61",
    //         chainName: "Smart Chain - Testnet",
    //         nativeCurrency: { name: "tBNB", symbol: "tBNB", decimals: 18 },
    //         rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    //         blockExplorerUrls: ["https://testnet.bscscan.com"],
    //       },
    //     ],
    //   });

    //   await activate(injected, isError);
    // };

    const isError = async (err) => {
      ethereum?.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x38",
            chainName: "Binance Smart Chain",
            nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
            rpcUrls: ["https://bsc-dataseed.binance.org"],
            blockExplorerUrls: ["https://bscscan.com/"],
          },
        ],
      });
      await activate(injected, isError);
    };

    const connectToMetamask = async () => {
      await ethereum.enable();
      await activate(injected, isError);
    };

    return (
      <>
        <Price>
          <div>
            {" "}
            {t("nft::price")} :
            <br />
            <b>
              {price && web3?.utils?.fromWei(price?.toString(), "ether")}
              &thinsp; BNB
            </b>
            <span>
              ≈ {i18n.language == "ru" ? "₽" : "$"}{" "}
              {price &&
                userStore.bnb &&
                i18n.language == "ru" &&
                (
                  userStore.bnb *
                  parseFloat(web3.utils.fromWei(price?.toString(), "ether"))
                ).toFixed(2)}
              {price &&
                userStore.bnbUsd &&
                i18n.language != "ru" &&
                (
                  userStore.bnbUsd *
                  parseFloat(web3.utils.fromWei(price?.toString(), "ether"))
                ).toFixed(2)}
            </span>
          </div>
        </Price>
        {!isCopied && (
          <Price style={{ marginBottom: "30px" }}>{t("nft::singleCopy")}</Price>
        )}
        {isCopied && (
          <Price
            style={{
              marginBottom: "30px",
              display: "flex",
              maxWidth: "469px",
              justifyContent: "space-between",
            }}
          >
            <div>
              {" "}
              {t("nft::copy")}
              :
              <br />
              <b>{copiesLeft + "/" + allCopies}</b>
            </div>

            {origCopies.filter((copy) => copy?.isSelling).length > 1 && (
              <div>
                {" "}
                Все предложения :
                <br />
                {authorOffer?.map((el, i) => {
                  // console.log('hiiii',el)
                  if (el?.isSelling) {
                    console.log("hiiii", el);
                    return (
                      <div
                        key={i}
                        onClick={() => newPrice(el, i)}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          borderTop:
                            i === border
                              ? "1px solid #959EA7"
                              : "1px solid transparent",
                          borderBottom:
                            i === border
                              ? "1px solid #959EA7"
                              : "1px solid transparent",
                          borderLeft: "0px",
                          borderRight: "0px",
                        }}
                      >
                        {el?.owner?.fullname}:
                        <b key={i}>
                          &thinsp;
                          {el.price &&
                            parseFloat(
                              web3.utils.fromWei(el?.price?.toString(), "ether")
                            )}
                          &thinsp;BNB
                        </b>
                      </div>
                    );
                  }
                })}
                {origCopies?.map((el, i) => {
                  if (
                    el?.isSelling &&
                    el.owner.user_id !== oneNFT.author.user_id
                  ) {
                    return (
                      <div
                        key={i}
                        onClick={() => newPrice(el, i + 1)}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          borderTop:
                            i + 1 === border
                              ? "1px solid #959EA7"
                              : "1px solid transparent",
                          borderBottom:
                            i + 1 === border
                              ? "1px solid #959EA7"
                              : "1px solid transparent",
                          borderLeft: "0px",
                          borderRight: "0px",
                        }}
                      >
                        {el.owner.fullname}:
                        <b key={i}>
                          &thinsp;
                          {el.price &&
                            parseFloat(
                              web3.utils.fromWei(el?.price?.toString(), "ether")
                            )}
                          &thinsp;BNB
                        </b>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </Price>
        )}
        {oneNFT?.isSelling && (
          <Buttons>
            {!account && (
              <SubmitButton
                style={{ marginTop: "4px" }}
                onClick={connectToMetamask}
                className="border-grarient"
              >
                {t("nft::connectMetamask")}
              </SubmitButton>
            )}

            <SubmitButton
              className="custom-bg-gradient"
              disabled={
                oneNFT?.owner?.user_id === userStore.user?.user_id ||
                stop ||
                copyOwner === userStore.user?.user_id ||
                !account
              }
              onClick={() => BuyNFT(oneNFT?.newId, oneNFT?.price)}
              style={{ marginTop: "4px" }}
            >
              <img
                src={require("../../../images/icons/cart-shopping.svg")}
                style={{
                  width: "17px",
                  height: "17px",
                  marginRight: "6px",
                  filter: "invert(1)",
                }}
              />
              {/* <FontAwesomeIcon icon={faShoppingCart} /> */}
              {t("nft::buy")}
            </SubmitButton>
          </Buttons>
        )}
      </>
    );
  }
);
