import { observer } from "mobx-react";
import { useState } from "react";
import { useInjection } from "inversify-react";
import { RegisterData, UserStore } from "../../../stores/user/UserStore";
import {
  FormOptions,
  ModalForm,
  TextInput,
  BG,
  Container,
  Title,
} from "../../../shared/forms/forms";
import PrivatePages from "../../../components/auth/auth";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const SignUpForm = observer(() => {
  const [formData, setFormData] = useState({
    public_key: "",
    fullname: "",
    phone: "",
    email: "",
    password: "",
    referal: localStorage.getItem("ref"),
  });
  const userStore = useInjection(UserStore);
  const { t } = useTranslation();
  const [password, setPassword] = useState();
  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const history = useHistory();

  const RepeatPassword = (e) => {
    setPassword(e.target.value);
  };

  const onSubmit = (e: any) => {
    const lang = localStorage.getItem("lang");
    e.preventDefault();
    if (localStorage.getItem("ref")) {
      setFormData({ ...formData, referal: localStorage.getItem("ref") });
    }
    if (password !== formData.password)
      return toast.error(t("toasts::notMatch"));
    // console.log(formData, 'form')
    userStore.Register(formData, lang);
  };

  const RedirectMain = () => {
    history.push("/p/catalog");
  };

  return (
    <PrivatePages>
      {!userStore.isRegister ? (
        <ModalForm
          onSubmit={onSubmit}
          title={`${t("form::registration")}`}
          button={`${t("button::create")}`}
        >
          <TextInput
            title={`${t("form::fullname")}`}
            name={"fullname"}
            required
            onChange={onChange}
            hidden={false}
          />
          <TextInput
            title={`${t("form::email")}`}
            name={"email"}
            required
            onChange={onChange}
            hidden={false}
          />
          <TextInput
            title={`${t("form::phone")}`}
            name={"phone"}
            onChange={onChange}
            hidden={false}
          />
          <TextInput
            title={`${t("form::password")}`}
            name={"password"}
            required
            onChange={onChange}
            hidden={true}
          />
          <TextInput
            title={`${t("form::repeatPassword")}`}
            name={"repeat_pass"}
            required
            onChange={RepeatPassword}
            hidden={true}
          />
          <FormOptions rememberPassword linkWallet acceptTerms />
        </ModalForm>
      ) : (
        <ModalForm
          onSubmit={RedirectMain}
          title={`${t("form::letterSended")}`}
          button={`${t("button::toMain")}`}
        ></ModalForm>
      )}
    </PrivatePages>
  );
});

export default SignUpForm;
