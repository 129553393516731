import { getDefaultDateFormat } from "../../utils/getDefaultDateFormat";
import styled from "styled-components";
import { useHistory } from "react-router";

export interface INewsItem {
  news: INews;
  index: number;
  langIndex: number;
}

export interface INews {
  file: string;
  createdAt: string;
  translations: INewsTranslation[];
  _id: string;
}

export interface INewsTranslation {
  title: string;
  description: string;
  lang?: string;
}

const NewsItem: React.FC<INewsItem> = ({ news, index, langIndex }) => {
  const { push } = useHistory();

  return (
    <NewsCard
      className="border-grarient"
      cardIndex={index}
      key={index}
      onClick={() => push(`/p/news/${news._id}`)}
    >
      <NewsCardImg
        src={`${process.env.REACT_APP_IP}/${news?.file}`}
        alt={news.translations[langIndex].title}
      />
      <NewsCardContent>
        <NewsCardContentTitle>
          {news.translations[langIndex].title}
        </NewsCardContentTitle>
        <NewsCardContentDate>
          {getDefaultDateFormat(news?.createdAt)}
        </NewsCardContentDate>
        <NewsCardContentDesc>
          {news.translations[langIndex].description}
        </NewsCardContentDesc>
      </NewsCardContent>
    </NewsCard>
  );
};

export default NewsItem;

const NewsCard = styled.div`
  margin-right: ${(props: { cardIndex: number }) =>
    props.cardIndex % 2 === 0 ? "25px" : "0px"};
  width: calc(50% - 12.5px);
  border-bottom: 1px solid #00000020;
  padding-bottom: 25px;
  margin-bottom: 25px;
  display: flex;
  cursor: pointer;
  position: relative;
  padding: 20px;
  @media (max-width: 950px) {
    width: 100%;
    margin-right: 0;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
const NewsCardImg = styled.img`
  width: 259px;
  margin-right: 25px;
  height: 175px;
  object-fit: cover;
  @media (max-width: 600px) {
    margin: 0 auto;
  }
`;
const NewsCardContent = styled.div`
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
  }
`;
const NewsCardContentTitle = styled.h3`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 12px;
  @media (max-width: 600px) {
    margin-top: 15px;
  }
`;
const NewsCardContentDate = styled.div`
  font-size: 14px;
  color: #7a7a7a;
  margin-bottom: 12px;
  font-weight: 500;
`;
const NewsCardContentDesc = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
`;
