import { ButtonLight, Filter, Sort } from "../../shared/buttons/buttons";
import "./catalog.sass";
import { observer } from "mobx-react";
import { useInjection } from "inversify-react";
import { useEffect, useRef, useState } from "react";
import { NFTstore } from "../../stores/NFTstore.";
import styled from "styled-components";
import FiltersBlock from "./filters";
import CatalogBlock from "./catalog";
import { Redirect } from "react-router";
import { isProd } from "../../constants";

const Filters = styled.div`
  @media (max-width: 700px) {
    & #desktop {
      display: none;
    }
    & #mobile {
      display: flex;
    }
  }
  @media (min-width: 701px) {
    & #desktop {
      display: flex;
    }
    & #mobile {
      display: none;
    }
  }
`;

const CatalogWrapper = styled.div`
  padding-top: 40px;
`;

const Catalog = observer(({ showDelayTokens }: any) => {
  const container = useRef();
  const nftStore = useInjection(NFTstore);
  const search = document.getElementById("navbarSearchInput");

  useEffect(() => {
    nftStore.showDelayTokens = showDelayTokens;
    console.log("hmhmmhmh", nftStore.showDelayTokens);

    nftStore.getNFTcatalog().then((res) => console.log(res, "res in comp"));
    if (nftStore.searchField?.length > 0) {
      setTimeout(() => {
        search.focus();
      }, 100);
    }
  }, []);

  useEffect(() => {
    return () => {
      nftStore.resetPage();
    };
  }, []);

  if (isProd) {
    return <Redirect to={"/p/development"} />;
  }
  window.onscroll = () => {
    let possibleScrollVal =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    if (
      window.scrollY >= possibleScrollVal &&
      location.pathname === "/p/catalog"
    ) {
      nftStore.loadNewPage();
    }
  };

  return (
    <CatalogWrapper>
      <div className="main-container" ref={container}>
        <FiltersBlock />
        <CatalogBlock showDelayTokens={showDelayTokens} />
      </div>
    </CatalogWrapper>
  );
});

export default Catalog;
