const Events = () => {
    return (
        <div style={{width: "100%", height: '50%',paddingTop: "10vh", position: 'absolute' }}>
            <div className='nft-img' style={{width:'40vw',marginLeft:"30vw"}}>
                <img src={require('../../images/logo.svg')} />
            </div>
            <h2 style={{ paddingTop: "10vh",textAlign: "center"}}>Ведутся технические работы</h2>
            
        </div>
    )
}


export default Events