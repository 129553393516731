// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const DropdownMenu = styled.div`
  position: absolute;
  top: 21px;
  width: max-content;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  padding: 16px 0px;
  background: #27215a;
  /* display: grid; */
  /* grid-template-columns: 1fr; */
  /* row-gap: 30px; */
  z-index: 9999;

  &:before {
    border-radius: 24px;
    z-index: -1;
  }
`;
export const DropdownMenuFilter = styled.div`
  position: absolute;
  top: 47px;
  width: max-content;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  padding: 16px 0px;
  background: #27215a;
  /* display: grid; */
  /* grid-template-columns: 1fr; */
  /* row-gap: 30px; */
  z-index: 9999;

  &:before {
    border-radius: 9px;
    z-index: -1;
  }
  @media (max-width: 985px) {
    top: 25px;
  }
`;
const DropdownChild = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  &:hover {
    background-color: #e8e8f3;
  }
  & div {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  & a {
    text-decoration: none;
    color: #273847;
    font-family: Montserrat-semiBold;
  }
`;
export const DropdownLink = styled(NavLink)`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  text-decoration: none;
  color: #fff;
  font-family: Montserrat-semiBold;
  margin: 0 1px;
  &:hover {
    color: #fff;
    background-color: #3d3a56;
  }
`;
export const DropdownLinkA = styled.a`
  height: 40px;
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
  text-decoration: none;
  color: #fff;
  font-family: Montserrat-semiBold;
  margin: 0;
  z-index: 99999;
  position: relative;
  margin: 0 1px;
  &:hover {
    background-color: #3d3a56;
  }
`;

export const DropdownP = styled.p`
  height: 40px;
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
  text-decoration: none;
  color: #fff;
  font-family: Montserrat-semiBold;
  margin: 0;
  z-index: 99999;
  position: relative;
  margin: 0 1px;
  &:hover {
    background-color: #3d3a56;
  }
`;
export const DropdownButton = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  text-decoration: none!impontant;
  color: #fff;
  font-family: Montserrat-semiBold;
  margin: 0 1px;
  &:hover {
    background-color: #3d3a56;
  }
`;
interface IDdItem {
  icon?: any;
}

type P = React.PropsWithChildren<IDdItem>;

export const DropdownItem = ({ children, icon }: P) => {
  return (
    <DropdownChild>
      {icon && (
        <div>
          <img src={icon} style={{ width: "17px", height: "17px" }} />
          {/* <FontAwesomeIcon icon={icon} /> */}
        </div>
      )}
      {children}
    </DropdownChild>
  );
};
