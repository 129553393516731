import { observer } from "mobx-react";
import "./profileComponents.sass";
import styled from "styled-components";
import { SearchInput } from "../../shared/forms/forms";
import { Chevron, Filter } from "../../shared/buttons/buttons";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { debounce } from "lodash";
import {
  DropdownMenuFilter,
  DropdownP,
} from "../../shared/buttons/dropdownMenu";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { NFTstore } from "../../stores/NFTstore.";
import { ICategory } from "./../../pages/profile/nft/types";
import { getNewsLanguageIndex } from "./../../utils/getNewsLanguageIndex";
import { useLanguageContext } from "../../context/LanguageContextProvider";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 144px 144px;
  column-gap: 30px;
  justify-items: center;
  margin-bottom: 40px;

  & input {
    width: 100%;
  }
  @media (max-width: 500px) {
    column-gap: 3px;
    grid-template-columns: 120px 120px 120px;
  }
  @media (max-width: 420px) {
    column-gap: 0px;
    grid-template-rows: 40px;
    grid-template-columns: 1fr 120px 120px;
    row-gap: 10px;
  }
`;
interface IFiltersBarParams {
  searchValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  category: string;
  setCategory: (category: string) => void;
  setSort?: (sort: string) => void;
  sort?: string;
  hasSort?: boolean;
}

const FiltersBar = observer(
  ({
    searchValue,
    onChange,
    hasSort = true,
    handleSubmit,
    category,
    setCategory,
    setSort,
    sort,
  }: IFiltersBarParams) => {
    const userStore = useInjection(UserStore);
    const nftStore = useInjection(NFTstore);
    const [sortDropdown, setSortDropdown] = useState(false);
    const [filterDropdown, setFilterDropdown] = useState(false);

    const { t } = useTranslation();
    const { language } = useLanguageContext();

    useEffect(() => {
      nftStore.getAllCategories();
    }, []);

    useEffect(() => {
      nftStore.getAllTags();
    }, []);

    const getCategories = (categories: ICategory[], lang: string) => {
      const result = [
        {
          label: t("button::all"),
          value: "",
        },
      ];

      categories &&
        categories?.forEach((category) => {
          const langIndex = getNewsLanguageIndex(category, language);
          result.push({
            label: category?.translations[langIndex]?.name,
            value: category?._id,
          });
        });
      return result;
    };

    const sortOptions = [
      {
        label: t("button::sortNew"),
        value: "&sort=date&sort=desc",
      },
      {
        label: t("button::sortPriceDown"),
        value: "&sort=price&sort=desc",
      },
      {
        label: t("button::sortPriceUp"),
        value: "&sort=price&sort=asc",
      },
      {
        label: t("button::sortPopular"),
        value: "&sort=popular&sort=desc",
      },
      {
        label: t("button::sortLastSold"),
        value: "&sort=dateSold&sort=desc",
      },
    ];

    const categoriesOptions = getCategories(nftStore.categories, language);

    return (
      <Container>
        <SearchInput
          onChange={onChange}
          searchValue={searchValue}
          handleSubmit={handleSubmit}
          border
        />

        <Filter
          id="mobile"
          className="border-grarient"
          style={{ flexWrap: "nowrap", zIndex: 9999 }}
          onMouseEnter={() => setFilterDropdown(true)}
          onMouseLeave={() => setFilterDropdown(false)}
        >
          {t("button::category")}
          {/* <Chevron src={require("../../images/chevron.png")} /> */}
          {filterDropdown && (
            <DropdownMenuFilter
              className="border-grarient absolute"
              style={{ right: "0" }}
            >
              {categoriesOptions?.map((categ) => {
                return (
                  <DropdownP
                    style={{
                      backgroundColor: categ.value == category ? "#3d3a56" : "",
                    }}
                    key={"filter" + categ.value}
                    onClick={() => setCategory(categ.value)}
                  >
                    {categ.label}
                  </DropdownP>
                );
              })}
            </DropdownMenuFilter>
          )}
        </Filter>

        {hasSort && (
          <Filter
            className="border-grarient"
            style={{ display: "flex", flexWrap: "nowrap", zIndex: 9998 }}
            onMouseEnter={() => setSortDropdown(true)}
            onMouseLeave={() => setSortDropdown(false)}
          >
            {t("button::sort")}
            {/* <Chevron src={require("../../images/chevron.png")} /> */}
            {sortDropdown && (
              <DropdownMenuFilter
                style={{ right: "0" }}
                className="border-grarient absolute"
              >
                {sortOptions.map((sortItem) => {
                  return (
                    <DropdownP
                      style={{
                        backgroundColor:
                          sortItem.value == sort ? "#3d3a56" : "",
                      }}
                      key={"sort" + sortItem.value}
                      onClick={() => setSort(sortItem.value)}
                    >
                      {sortItem.label}
                    </DropdownP>
                  );
                })}
              </DropdownMenuFilter>
            )}
          </Filter>
        )}
      </Container>
    );
  }
);

export default FiltersBar;
