import { ButtonLight, Filter, Sort } from "../../shared/buttons/buttons";
import "./catalog.sass";
import CatalogItem from "./catalogItem";
import { observer } from "mobx-react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownLink,
  DropdownMenu,
  DropdownP,
} from "../../shared/buttons/dropdownMenu";
import Loader from "react-loader-spinner";
import { NFTstore } from "../../stores/NFTstore.";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Filters = styled.div`
  @media (max-width: 700px) {
    & #desktop {
      display: none;
    }
    & #mobile {
      display: flex;
    }
  }
  @media (min-width: 701px) {
    & #desktop {
      display: flex;
    }
    & #mobile {
      display: none;
    }
  }
`;

const FiltersBlock = observer(() => {
  const nftStore = useInjection(NFTstore);
  const [page, setPage] = useState(1);
  const [filterArray, setFilterArray] = useState([]);
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [sortDropdown, setSortDropdown] = useState(false);
  const [tags, setTags] = useState([]);
  const { i18n, t } = useTranslation();

  // useEffect(() => {
  //   nftStore.getAllTags();
  //   console.log("i18n", i18n);
  // }, []);
  // useEffect(()=>{
  //    if(nftStore.tags){
  //        const tagarr = nftStore.tags.map((el,i)=>{
  //            return {}
  //        })
  //    }
  // },[nftStore.tags, i18n.language])
  const filtersAuc = {
    field: "auction.open",
    filters: ["Изображение", "Векторное изображение", "Видео"],
  };
  const filtersTags = {
    field: "tags",
    filters: nftStore.tags,
  };
  // const filters = [
  //     {
  //         field: 'category',
  //         filters: ['Изображение','Векторное изображение','Видео','Другое','Искусство','Фотографии','Игры','Музыка','Спорт',],
  //     },
  //     {
  //         field: 'auction',
  //         filters: ['Изображение','Векторное изображение','Видео','Другое','Искусство','Фотографии','Игры','Музыка','Спорт',],
  //     }
  // ]

  useEffect(() => {
    // nftStore.getNFTcatalog().then(res => console.log(res, 'res in comp'))
    // console.log('here are filters',nftStore.sort)
  }, [nftStore.sort]);
  return (
    <div className="ct-filters">
      <Filters>
        <div id="desktop">
          {/* <Filter
            style={{
              backgroundColor: nftStore.filters.some(
                (item) => item[0] === "auction.open" && item[1] === true
              )
                ? "white"
                : "",
            }}
            onClick={() => nftStore.toggleFilter(["auction.open", true])}
          >
            {t("filters::auction")}
          </Filter>
          <Filter
            style={{
              backgroundColor: nftStore.filters.some(
                (item) => item[0] === "auction.open" && item[1] === false
              )
                ? "white"
                : "",
            }}
            onClick={() => nftStore.toggleFilter(["auction.open", false])}
          >
            {t("filters::sale")}
          </Filter> */}

          {/* {filtersTags.filters?.map((el, i) => {
            const active = nftStore.filters.some((item) => item[1] === el.tag);
            return (
              <Filter
                style={{ backgroundColor: active ? "white" : "" }}
                onClick={() =>
                  nftStore.toggleFilter([filtersTags.field, el.tag])
                }
                key={"filter" + i}
              >
                {el.languages
                  ? el.languages[i18n.language] && el.languages[i18n.language]
                  : el.tag}
              </Filter>
            );
          })} */}
        </div>
        <Sort
          id="mobile"
          className="border-grarient"
          style={{ flexWrap: "nowrap", zIndex: 300 }}
          onMouseEnter={() => setFilterDropdown(true)}
          onMouseLeave={() => setFilterDropdown(false)}
        >
          {t("button::filters")}

          {filterDropdown && (
            <DropdownMenu style={{ left: "0px" }} className="border-grarient">
              <DropdownP
                style={{
                  backgroundColor: nftStore.filters.some(
                    (item) => item[0] === "auction.open" && item[1] === true
                  )
                    ? "#3d3a56"
                    : "",
                }}
                onClick={() => nftStore.toggleFilter(["auction.open", true])}
              >
                {t("filters::auction")}
              </DropdownP>
              <DropdownP
                style={{
                  backgroundColor: nftStore.filters.some(
                    (item) => item[0] === "auction.open" && item[1] === false
                  )
                    ? "#3d3a56"
                    : "",
                }}
                onClick={() => nftStore.toggleFilter(["auction.open", false])}
              >
                {t("filters::sale")}
              </DropdownP>
              {filtersTags.filters?.map((el, i) => {
                const active = nftStore.filters.some(
                  (item) => item[1] === el.tag
                );
                return (
                  <DropdownP
                    style={{ backgroundColor: active ? "#3d3a56" : "" }}
                    key={"filter" + i}
                    onClick={() =>
                      nftStore.toggleFilter([filtersTags.field, el.tag])
                    }
                  >
                    {el.tag}
                  </DropdownP>
                );
              })}
            </DropdownMenu>
          )}
        </Sort>
      </Filters>

      <Sort
        style={{ display: "flex", flexWrap: "nowrap", zIndex: 300 }}
        onMouseEnter={() => setSortDropdown(true)}
        onMouseLeave={() => setSortDropdown(false)}
        className="border-grarient"
      >
        {t("button::sort")}

        {sortDropdown && (
          <DropdownMenu className="border-grarient">
            <DropdownP onClick={() => nftStore.toggleSort("date", "desc")}>
              {t("button::sortNew")}
            </DropdownP>
            <DropdownP onClick={() => nftStore.toggleSort("price", "desc")}>
              {t("button::sortPriceDown")}
            </DropdownP>
            <DropdownP onClick={() => nftStore.toggleSort("price", "asc")}>
              {t("button::sortPriceUp")}
            </DropdownP>
            <DropdownP onClick={() => nftStore.toggleSort("popular", "desc")}>
              {t("button::sortPopular")}
            </DropdownP>

            {/* <DropdownLink to=''>Коллаборации</DropdownLink> */}
            {/* <DropdownLink to=''>Продажа</DropdownLink>
                        <DropdownLink to=''>Аукцион</DropdownLink> */}
          </DropdownMenu>
        )}
      </Sort>
    </div>
  );
});

export default FiltersBlock;
