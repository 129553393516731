import styled from "styled-components";
import { useInjection } from "inversify-react";
import { useTranslation } from "react-i18next";
import { ButtonLight } from "./../../../shared/buttons/buttons";
import { useEffect, useState } from "react";
import { NFTstore } from "./../../../stores/NFTstore.";
import { getNewsLanguageIndex } from "./../../../utils/getNewsLanguageIndex";
import { useLanguageContext } from "./../../../context/LanguageContextProvider";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";

const FooterWrapper = styled.footer`
  padding-bottom: 112px;
  margin-top: 40px;
`;
const Container = styled.div``;
const FooterBorderWrapper = styled.div`
  padding: 60px 50px 25px;
  &:before: {
    z-index: -1;
  }
`;
const FooterContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  color: #fff;
  font-size: 22px;
  line-height: 1.5em;
  padding-bottom: 35px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const FooterContentLeft = styled.div`
  width: 240px;
  & img {
    max-width: 164px;
    margin-bottom: 20px;
  }
  & p {
    font-size: 15px;
    line-height: 1.5em;
    margin-bottom: 0;
  }
  & a {
    font-size: 15px;
  }

  @media (max-width: 992px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
const FooterContentRight = styled.div`
  width: 75%;
  display: flex;
  justify-content: space-between;
  & h3 {
    font-size: 32px;
    margin-bottom: 13px;
  }

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FooterColumnWrapper = styled.div`
  width: 32%;
  margin-left: 1%;
  & p {
    margin-bottom: 0;
    line-height: 1.2em;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FooterLinkText = styled.p`
  & a {
    font-size: 15px;
    text-decoration: none;
    color: #fff;
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
`;

const FOOTER_COLUMNS = [1, 2, 3];

const Footer = observer(() => {
  const { t } = useTranslation();
  const { language } = useLanguageContext();
  const nftStore = useInjection(NFTstore);

  const [pages, setPages] = useState([]);

  console.log(pages);

  useEffect(() => {
    if (nftStore.additionalPages) {
      const pagesValue = nftStore.additionalPages.map((page) => {
        const langIndex = getNewsLanguageIndex(page, language);
        return {
          title: page.translations[langIndex].title,
          path: `${!page?.isExternal ? "/p/pages/" : ""}${page.url}`,
          footerColIndex: page?.footerColIndex,
          isExternal: page?.isExternal,
        };
      });
      setPages(pagesValue);
    }
  }, [nftStore.additionalPages, language]);

  return (
    <FooterWrapper>
      <Container className="main-container">
        <FooterBorderWrapper className="border-grarient">
          <FooterContent>
            <FooterContentLeft>
              <img
                src={require("./../../../images/transparent-logo.png")}
                alt="logo"
              />
              <p>
                {`${t("footer::email-support")} `}
                <a href="mailto:info@mechanicsofwonder.com">
                  info@mechanicsofwonder.com
                </a>
              </p>
            </FooterContentLeft>
            <FooterContentRight>
              {FOOTER_COLUMNS.map((columnIndex) => {
                return (
                  <FooterColumnWrapper
                    key={columnIndex}
                    className={String(columnIndex)}
                  >
                    {pages
                      .filter((item) => {
                        return columnIndex === item.footerColIndex;
                      })
                      .map((page) => (
                        <FooterLinkText key={page.title}>
                          {page?.isExternal ? (
                            <a href={page.path}>{page.title}</a>
                          ) : (
                            <NavLink to={page.path}>{page.title}</NavLink>
                          )}
                        </FooterLinkText>
                      ))}
                  </FooterColumnWrapper>
                );
              })}
            </FooterContentRight>
          </FooterContent>
        </FooterBorderWrapper>
      </Container>
    </FooterWrapper>
  );
});

export default Footer;
