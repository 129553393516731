import { useHistory } from "react-router";
import { url } from "../../constants";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import React from "react";

export interface IProfileItem {
  user_id: string;
  photo: string;
  user_type: string;
  fullname: string;
}

interface IParams {
  user: IProfileItem;
}

const ProfileItem: React.FC<IParams> = ({ user }) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  return (
    <Wrapper
      onClick={() => push(`profile/artist/${user.user_id}`)}
      className="border-grarient"
    >
      <ProfileImg src={`${url}/${user.photo}`} />
      <ProfileTitle>{user?.fullname || t("profile::no-name")}</ProfileTitle>
      <ProfileStatus>
        {t(user?.user_type === "artist" ? "profile::artist" : "profile::user")}
      </ProfileStatus>
    </Wrapper>
  );
};

export default ProfileItem;

//Profile

const Wrapper = styled.div`
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 2px 15px rgba(39, 56, 71, 0.15);
  position: relative;
  padding: 12px;
  transition: all 0.3s ease-in-out;
  cursor: default;

  &:hover {
    transform: scale(1.01);
  }
`;

const ProfileImg = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
  overflow: hidden;
  object-fit: cover;
`;

const ProfileTitle = styled.div`
  font-family: Releway-bold;
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin-bottom: 8px;
`;

const ProfileStatus = styled.div`
  font-size: 14px;
  color: #8458ef;
  text-align: center;
`;
