import { Sort } from "./../../shared/buttons/buttons";
import axios from "axios";
import { injectable } from "inversify";
import { action, makeObservable, observable, configure } from "mobx";
import "reflect-metadata";
import { innerBackend, setAuthToken, url } from "../../components/auth/helper";
import { RootStore } from "../RootStore";
import { toast } from "react-toastify";
import { ProfileChapter } from "./../../utils/getVisibleProfileRoutes";
import { IReview } from "./../../pages/Reviews";

configure({
  enforceActions: "never",
});
@injectable()
export class UserStore {
  @observable user?: RegisterData;
  @observable filters = [];
  @observable sort: any;
  @observable nowSorting: any;
  @observable isAuth: boolean = false;
  @observable isError: boolean;
  @observable isRegister: boolean = false;
  @observable isValid: boolean = false;
  @observable isLogOut: boolean = false;
  @observable NFT: any;
  @observable BSChistory: any = [];
  @observable collections: any = [];
  @observable collectionList: any = [];
  @observable seo: any = [];
  @observable posts = [];
  @observable oneCollection: any;
  @observable bnb: any;
  @observable bnbUsd: any;
  @observable ownedNFT: any;
  @observable mintedNFT: any;
  @observable banner: any;
  @observable currentPostNft: any;
  @observable currentPost: any;
  @observable profileChapters: ProfileChapter[] | null = null;
  @observable reviews: IReview[] | null = null;

  public constructor(private readonly rootStore: RootStore) {
    makeObservable(this);
    this.isError = false;
  }

  //@POST /users/auth {email:email,password:password} - авторизация
  //его в POST /usman/validate/:id
  //POST /usman/auth {password,field,content}
  //филд - email или public_key
  //POST /usman/auth {password,field,content}
  @action async Login(formData: any) {
    try {
      const res = await axios.post(`${url}/usman/auth`, formData);
      const token = res.data.token;
      localStorage.setItem("token", token);
      setAuthToken(token);
      this.isAuth = true;
      this.isLogOut = false;
    } catch (err) {
      this.isAuth = false;
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async MetaLogin(token: string) {
    try {
      localStorage.setItem("token", token);
      setAuthToken(token);
      this.isAuth = true;
      this.isLogOut = false;
    } catch (err) {
      this.isAuth = false;
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  Auth() {
    this.isAuth = true;
  }
  async changPostsNft(nftId, id) {
    let body = {
      ids: [nftId],
    };
    try {
      const res = await innerBackend.post(
        `${url}/microblog/post/nfts/change/${id}`,
        body
      );
      this.posts = res.data.posts;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async clearPostStore() {
    this.posts = [];
  }
  async deletePost(id) {
    try {
      const res = await innerBackend.post(`${url}/microblog/post/delete/${id}`);
      this.getposts();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async getUserPosts(id) {
    try {
      const res = await innerBackend.get(`${url}/microblog/user/posts/${id}`);
      this.posts = res.data.posts;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async getposts() {
    console.log("mypoasts");
    try {
      const res = await innerBackend.get(`${url}/microblog/user/me/posts`);
      if (res.data.length !== 0) {
        this.posts = res.data;
      }

      console.log(res.data);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async subscribeToUser(id) {
    console.log(id);
    try {
      const res = await innerBackend.post(
        `${url}/microblog/user/subscribe/${id}`
      );
      console.log(res.data);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async editPost(title, text, id) {
    let body = {
      text: text,
      title: title,
    };
    try {
      const res = await innerBackend.post(
        `${url}/microblog/post/edit/${id}`,
        body
      );
      this.posts = res.data.posts;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async getSEO() {
    try {
      const res = await innerBackend.get(`${url}/admin/seo/tags/get`);
      this.seo = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  setSeo = (seo) => {
    this.seo = seo;
  };
  async setCurrentPost(
    title: string | null,
    text: string | null,
    _id: string | null
  ) {
    if (title) {
      this.currentPost = {
        title: title,
        text: text,
        _id: _id,
      };
    }
  }
  async createNewPost(formData) {
    console.log(formData);
    try {
      const res = await innerBackend.post(
        `${url}/microblog/post/create`,
        formData
      );

      this.getposts();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async getChaimHistory() {
    try {
      console.log("try");
      const res = await innerBackend.get(`${url}/user/transactions/history`);
      console.log(res.data, "history");
      this.BSChistory = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async getBnbPriceRub() {
    try {
      const res = await innerBackend.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=rub`
      );
      this.bnb = res.data.binancecoin.rub;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async setCurrentPostNft(
    id: string | null,
    name?: string,
    link?: string,
    type?: string
  ) {
    if (type) {
      console.log(
        "%cUserStore.ts line:202 object",
        "color: #007acc;",
        window.location
      );
      this.currentPostNft = {
        id: id,
        name: name,
        link: url + "/" + link,
        type: type,
        openurl: window.location.origin + "/p/nft/" + id,
      };
    } else if (!type && id) {
      this.currentPostNft = {
        id: id,
        name: name,
      };
    } else {
      this.currentPostNft = null;
    }
  }
  async getBnbPriceUsd() {
    try {
      const res = await innerBackend.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd`
      );
      this.bnbUsd = res.data.binancecoin.usd;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async editCollection(value, field, id) {
    console.log(value, field, id);
    try {
      let body = {
        [field]: value,
      };
      const res = await innerBackend.post(
        `${url}/collections/edit/${id}`,
        body
      );
      console.log(res);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async deleteCollection(id, text) {
    try {
      const res = await innerBackend.delete(`/collections/delete/${id}`);
      // this.collections = res.data
      toast.success(text);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async LogOut() {
    try {
      localStorage.removeItem("token");
      setAuthToken(null);
      this.isLogOut = true;
      this.isAuth = false;
    } catch (err) {
      this.isAuth = false;
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  ChangePassword = async (formData) => {
    try {
      const res = await innerBackend.put(`${url}/usman/pwd/change`, formData);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  ChangeBanner = async (formData) => {
    console.log(formData);
    try {
      const res = await innerBackend.post(
        `${url}/user/banner/change`,
        formData
      );
      this.banner = res.data;
      console.log(res.data);
    } catch (err) {
      console.log("asdas");
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  getCollections = async (id) => {
    try {
      const res = await innerBackend.get(
        `${url}/collections/user/${id === "self" ? this.user._id : id}`
      );
      this.collections = res.data;
      console.log(res.data);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  clearCollections = async () => {
    this.collections = null;
  };
  getCollectionsList = async (value) => {
    try {
      const res = await innerBackend.get(
        `${url}/collections/getcol/query?name=${value}`
      );
      this.collectionList = res.data;
      console.log(res.data);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  getOneCollection = async (id) => {
    console.log(id);
    try {
      const res = await innerBackend.get(`${url}/collections/get/${id}`);
      this.oneCollection = res.data;
      console.log("collection", res.data);
      return;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };

  @action async Register(formData, lang) {
    try {
      const res = await axios.post(
        `${url}/usman/register?lang=${lang}`,
        formData
      );
      this.isRegister = true;
      toast.success(res.data.msg);
      let data = {
        msg: res.data.msg,
        status: true,
      };
      localStorage.removeItem("ref");
      return data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
      let data = {
        msg: msg,
        status: false,
      };
      return data;
    }
  }
  //его в POST /usman/validate/:id
  async Validation(code: any) {
    try {
      const res = await axios.post(`${url}/usman/validate/${code.id}`);
      console.log(res.status, "status");
      return true;
    } catch (err) {
      console.log("error here");
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
      return false;
    }
  }
  async getOwnedNFT(
    id: string,
    params: { isSelling: boolean; categoryId?: string; title?: string },
    sort: string
  ) {
    try {
      const res = await axios.get(
        `${url}/user/get/owned/${id}?${sort ? sort : ""}`,
        { params }
      );
      this.ownedNFT = res?.data;
      return true;
    } catch (err) {
      let msg = err?.response?.data?.err;
      err?.message && toast.error(msg);
      return false;
    }
  }
  async getMintedNFT(id: any, params) {
    try {
      const res = await axios.get(`${url}/user/get/mintedd/${id}`, { params });
      this.mintedNFT = res?.data;
      return true;
    } catch (err) {
      console.log("error here");
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
      return false;
    }
  }
  profileSearchFilter = async (search, user_id) => {
    console.log(search);
    this.filters = [["title", search]];
    return true;
  };
  toggleFilter = async (filter) => {
    const id = this.filters.findIndex((el) => el[0] === filter[0]);
    if (filter[1]) {
      if (id >= 0) {
        this.filters.splice(id, 1, filter);
      } else {
        this.filters = [...this.filters, filter];
      }
      return true;
    } else {
      this.filters.splice(id, 1);
    }
  };
  toggleSort = async (field, way) => {
    this.nowSorting = [field, way];
    this.sort = `&sort=${field}&sort=${way}`;
    return true;
  };

  async ValidateReStore(code: any) {
    try {
      const res = await axios.post(`${url}/usman/pwdchange2/${code.id}`);
      console.log(res.status, "status");
      return true;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
      return false;
    }
  }

  sendNewPassword = async (formData, params) => {
    try {
      const res = await axios.post(
        `${url}/usman/pwdchange3/${params.id}`,
        formData
      );
      return true;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
      return false;
    }
  };

  restorePassword = async (formData) => {
    try {
      const res = await innerBackend.post(`${url}/usman/pwdchange1`, formData);
      return true;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
      return false;
    }
  };

  withdraw = async (formData) => {
    try {
      const res = await innerBackend.post(`${url}/user/extract`, formData);
      // toast.success('Отправлено')
      return true;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
      return false;
    }
  };
  updateProfile = async (formData: RegisterData) => {
    try {
      console.log("changeData 1", formData);

      const res = await innerBackend.put(`${url}/user/me/edit`, formData);
      console.log("changeData 2", res.data);
      this.user = res.data;
      return true;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  uploadAvatar = async (AvatarData) => {
    try {
      const res = await innerBackend.put(`${url}/user/me/avatar`, AvatarData);
      this.user = res.data;
      return true;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  //  changeTypeOld = async (type) => {
  //   try {

  //     const res = await innerBackend.put(`${url}/user/me/edit`, {user_type:type});
  //     console.log('changetype',res.data)
  //     this.user = res.data
  //     toast.success('Тип изменен')
  //     // this.updateProfile(formData)
  //   } catch (err) {
  //     let msg = err.response.data.err
  //     err?.message && toast.error(msg)
  //   }
  // }

  changeType = async (formData) => {
    try {
      console.log("changetype 1");

      const res = await innerBackend.post(`${url}/user/edit/type`);
      console.log("changetype", res.data);
      this.user = res.data;
      toast.success("Заявка отправлена");
      this.updateProfile(formData);
    } catch (err) {
      console.log("changetype error", err.response);

      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  };
  createCollection = async (body) => {
    console.log(body);
    try {
      const res = await innerBackend.post(`${url}/collections/new`, body);

      console.log("dadadad", res);
    } catch (err) {
      console.log("dadadad error", err.response);
    }
  };
  stopSelling = async (id, formData) => {
    console.log(id);
    try {
      const res = await innerBackend.post(
        `${url}/nft/withdraw/${id}`,
        formData
      );
      this.user = res.data;
      console.log("dadadad", res);
    } catch (err) {
      console.log("dadadad error", err.response);
    }
  };
  startSelling = async (id, formData) => {
    console.log(id, formData);
    try {
      const res = await innerBackend.post(
        `${url}/nft/put/sale/${id}`,
        formData
      );
      this.user = res.data;
      console.log("dadadad", res);
    } catch (err) {
      console.log("dadadad error", err.response);
    }
  };
  addTokenToWishlist = async (token_id) => {
    try {
      const res = await innerBackend.post(`${url}/nft/wishlist/${token_id}`);
      return true;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
      return false;
    }
  };
  startAuction = async (id, formData) => {
    console.log(id, formData);
    try {
      const res = await innerBackend.post(
        `${url}/nft/put/auction/${id}`,
        formData
      );
      this.user = res.data;
      console.log("dadadad", res);
    } catch (err) {
      console.log("dadadad error", err.response);
    }
  };

  GetUser = async (token: string | null) => {
    try {
      setAuthToken(token);

      if (!this.isLogOut) {
        const res = await innerBackend.get(`${url}/user/user/me`);
        console.log("get user", res);
        this.user = res.data;
        this.isAuth = true;
      }

      return true;
    } catch (err) {
      if (err) return false;
    }
  };

  //chapters
  GetProfileChapters = async () => {
    try {
      const res = await innerBackend.get(`${url}/section`);
      this.profileChapters = res.data;
    } catch (err) {
      if (err) return false;
    }
  };

  //rewiews
  createReview = async (formData) => {
    try {
      await innerBackend.post(`${url}/review`, formData);
      toast.success("Отзыв успешно отправлен");
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
      return false;
    }
  };
  async getAllReviews() {
    try {
      const res = await innerBackend.get(`${url}/review`);
      this.reviews = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
}

export interface LoginData {
  password: string;
  field?: string;
  value: string; //email / public key
}

export interface RegisterData {
  public_key?: string;
  user_type?: string;
  metamask?: string;
  photo?: string;
  fullname?: string;
  phone?: string;
  referals?: any;
  email?: string;
  password?: string;
  user_id?: string;
  referal_code?: string;
  balance?: string;
  referal?: string;
  about?: string;
  education?: string;
  social?: string;
  // null?: null,
  works?: any;
  _id?: string;
  banner?: string;
  wishlist?: any;
  type_pending?: boolean;
  subscriptions: any;
}
