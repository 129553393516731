import { observer } from "mobx-react";
import "./profileComponents.sass";
import CatalogItem from "../../pages/Catalog/catalogItem";

import styled from "styled-components";
import SearchBar from "./searchBar";
import FiltersBar from "./filtersBar";
import { ButtonLight, ButtonText } from "../../shared/buttons/buttons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";
import { useHistory } from "react-router";

const SwitchMenu = styled.div`
  & button {
    font-family: Releway-semiBold;
    font-size: 18px;
    margin-right: 18px;
    border-bottom: 2px solid transparent;
    margin-bottom: 20px;
    &:hover {
      border-color: #894ada;
    }
  }
`;

const NothingHere = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  align-items: flex-start;
  justify-content: center;
  // border: 1px solid rgba(211, 211, 211, 1);
  border-radius: 8px;

  h1 {
    font-size: 32px;
  }
  &:before {
    z-index: 1;
  }
`;
const Text = styled.div`
  position: relative;
  z-index: 2;
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;
  color: #fff;
  p {
    text-align: left;
    max-width: 365px;
  }
`;

const MyNFT = observer(({ user }) => {
  const [ammount, setAmmount] = useState(4);
  const { t } = useTranslation();
  const userStore = useInjection(UserStore);
  const history = useHistory();
  const [swithMenuState, setSwithMenuState] = useState(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [sort, setSort] = useState<string>("");

  const getParams = (eSearchValue: string) => {
    const categoryValue = category ? { categoryId: category } : {};
    const searchvalue = eSearchValue ? { title: eSearchValue } : {};
    return {
      isSelling: swithMenuState ? true : false,
      ...categoryValue,
      ...searchvalue,
    };
  };

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    getOwnedNFT(e.target.value);
    setSearchValue(e.target.value);
  };

  const getOwnedNFT = (value = searchValue) => {
    userStore.getOwnedNFT(user?._id, getParams(value), sort);
  };

  const switchOptions = ["pSelling::inactiveNFT", "pSelling::onSale"];

  useEffect(() => {
    if (user) {
      getOwnedNFT(searchValue);
    }
  }, [user, swithMenuState, category, sort]);

  return (
    <SmoothAppearance trigger={userStore.ownedNFT}>
      <div className="lk-myNft">
        <FiltersBar
          handleSubmit={() => {
            getOwnedNFT(searchValue);
          }}
          searchValue={searchValue}
          onChange={onChangeInputValue}
          category={category}
          setCategory={setCategory}
          sort={sort}
          setSort={setSort}
        />
        {/* <SwitchMenu>
          {switchOptions.map((text, index) => {
            return (
              <ButtonText
                key={index}
                onClick={() => setSwithMenuState(index)}
                style={{
                  borderColor: swithMenuState === index ? "#894ADA" : "",
                }}
              >
                {t(text)}
              </ButtonText>
            );
          })}
        </SwitchMenu> */}

        {userStore.ownedNFT?.length > 0 ? (
          <div className="mynft-container">
            {userStore.ownedNFT?.map((el, i) => {
              if (i < ammount) {
                return (
                  <CatalogItem
                    key={"my-nft" + i}
                    el={el}
                    isAuc={true}
                    isProfile
                    isCurrentUser={userStore.user?._id === user?._id}
                  />
                );
              }
            })}
          </div>
        ) : (
          <>
            {userStore.user?._id === user?._id && (
              <NothingHere
                style={{
                  display: user?._id === user?._id ? "flex" : "none",
                }}
                className="border-grarient"
              >
                <Text>
                  <h1>{t("pMyNft::nothing")}</h1>
                  <p>{t("pMyNft::uCanBuyNft")}</p>
                  <ButtonLight
                    className="custom-bg-gradient"
                    style={{ width: "70%", minWidth: "180px" }}
                    onClick={() => history.push("../../../../p/catalog")}
                  >
                    {t("button::toCatalog")}
                  </ButtonLight>
                </Text>

                <img
                  src={require("../../images/looking-out.svg")}
                  style={{ padding: "28px" }}
                />
              </NothingHere>
            )}
          </>
        )}
        {ammount < user?.works?.length && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0 0",
            }}
          >
            <ButtonLight width="200px" onClick={() => setAmmount(ammount + 4)}>
              {t("button::showMore")}
            </ButtonLight>
          </div>
        )}
      </div>
    </SmoothAppearance>
  );
});

export default MyNFT;
