import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

type ContextProps = {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
};

export const LanguageContext = React.createContext<ContextProps>({
  language: "",
  setLanguage: () => {},
});

export const useLanguageContext = () => {
  const context = React.useContext(LanguageContext);
  if (!context) {
    throw new Error(
      "useLanguageContext must be used within a LanguageContextProvider"
    );
  }
  return context;
};

export const LanguageContextProvider = observer(({ children }) => {
  const localStorageLang = localStorage.getItem("lang");
  const [language, setLanguage] = useState<string>("ru");

  useEffect(() => {
    setLanguage(localStorageLang);
  }, []);

  const value = {
    language,
    setLanguage,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
});
