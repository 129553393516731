import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useInjection } from "inversify-react";

import { UserStore } from "../../../stores/user/UserStore";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { NFTstore } from "../../../stores/NFTstore.";
import { getDefaultDateFormat } from "./../../../utils/getDefaultDateFormat";
import { SmoothAppearance } from "../../../shared/layout/smoothAppearance";

import { getNewsLanguageIndex } from "./../../../utils/getNewsLanguageIndex";
import { useLanguageContext } from "../../../context/LanguageContextProvider";
import NewsItem, { INews } from "../../../components/newsItem";

const NewsWrapper = styled.div`
  padding-top: 50px;
  color: #fff;
`;
const Container = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    max-width: 95%;
  }
`;
const NewsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const News = observer(() => {
  const nftStore = useInjection(NFTstore);
  const { language } = useLanguageContext();

  useEffect(() => {
    nftStore.getAllNews();
  }, []);

  const news = nftStore.news;

  return (
    <SmoothAppearance trigger={news}>
      <NewsWrapper>
        <Container>
          <NewsContent>
            {news &&
              news.map((news, index) => {
                const langIndex = getNewsLanguageIndex(news, language);

                return (
                  <NewsItem news={news} index={index} langIndex={langIndex} />
                );
              })}
          </NewsContent>
        </Container>
      </NewsWrapper>
    </SmoothAppearance>
  );
});

export default News;
