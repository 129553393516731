import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import {
  NavLink,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {
  DropdownLink,
  DropdownLinkA,
  DropdownMenu,
} from "../../buttons/dropdownMenu";
import styled from "styled-components";
import { UserStore } from "../../../stores/user/UserStore";
import NavbarButton from "./navbarButton";
import { MutableRefObject, useEffect, useRef, useState } from "react";
// import { faBars, faExclamation, faFutbol, faGamepad, faHourglassStart, faMusic, faPallet, faTh } from '@fortawesome/free-solid-svg-icons'
import { SearchInput } from "../../../shared/forms/forms";
import { UIStore } from "../../../stores/uiStore/uiStore";
import { useTranslation } from "react-i18next";
import { NFTstore } from "../../../stores/NFTstore.";
import i18n from "../../../i18n";
import { getNewsLanguageIndex } from "./../../../utils/getNewsLanguageIndex";
import { useLanguageContext } from "../../../context/LanguageContextProvider";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const Path = process.env.REACT_APP_IP;

const Container = styled.div`
  width: 100%;
  height: 117px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  background-color: #02155a;
  opacity: 1 !important;
`;

const Flex = styled.div`
  display: grid;
  grid-template-columns: max-content minmax(240px, 2fr) 1fr repeat(
      7,
      max-content
    );
  column-gap: 40px;
  align-items: center;
  padding: 0 20px;
  height: 100%;

  @media (max-width: 1300px) {
    column-gap: 20px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: max-content minmax(200px, 2fr) 1fr max-content max-content;
  }
  @media (max-width: 600px) {
    grid-template-columns: max-content 1fr max-content max-content;
    & span {
      display: none;
    }
  }
`;

const AvatarMenu = styled.div`
  & img {
    width: 32px;
    height: 32px;
    // border: 1px solid #ffffff;
    border-radius: 100%;
    box-sizing: border-box;
    overflow: hidden;
    object-fit: cover;
  }

  @media (max-width: 1000px) {
    & .desktop {
      display: none;
    }
    & .mobile {
      display: grid;
    }
  }
  @media (min-width: 1001px) {
    & .desktop {
      display: grid;
    }
    & .mobile {
      display: none;
    }
  }
`;
const Logo = styled.div`
  font-size: 24px;
  letter-spacing: 0.04em;
`;
const LogoFirstText = styled.span`
  font-weight: 800;
`;
const LogoSecondText = styled.span`
  font-weight: 500;
`;

const WalletWrapper = styled.div`
  display: block;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Navbar = observer(() => {
  const userStore = useInjection(UserStore);
  const nftStore = useInjection(NFTstore);
  const uiStore = useInjection(UIStore);
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { language } = useLanguageContext();
  let match = useRouteMatch();
  const [avaDropdown, setAvaDropdown] = useState(false);
  const [pages, setPages] = useState([]);

  const searchInputRef = useRef<MutableRefObject<HTMLInputElement>>(null);

  useEffect(() => {
    if (history.location.pathname === "/p/search") {
      setTimeout(() => {
        searchInputRef?.current?.focus();
      }, 50);
    }
  }, [nftStore.searchField, history.location.pathname]);

  useEffect(() => {
    if (nftStore.additionalPages) {
      const initialValue = {
        path: "p/reviews",
        title: `${t("reviews::title")}`,
      };

      const pagesValue = nftStore.additionalPages
        .filter((item) => item?.isVisible)
        .map((page) => {
          const langIndex = getNewsLanguageIndex(page, language);
          return {
            title: page.translations[langIndex].title,
            path: `p/pages/${page.url}`,
          };
        });
      setPages([initialValue, ...pagesValue]);
    }
  }, [nftStore.additionalPages, language]);

  console.log(JSON.parse(JSON.stringify(pages)));

  const debounce = (func) => {
    let timer;
    // console.log('1')

    return (...args) => {
      // console.log('2')

      clearTimeout(timer);
      // console.log('22')

      timer = setTimeout(() => {
        console.log("3");
        func.apply(this, args);
      }, 50);
    };
  };

  const onSearch = (e) => {
    e.preventDefault();
    if (history.location.pathname !== "/p/search") {
      history.push("/p/search");
    }

    nftStore.navSearchBarCatalogFilter(e.target.value);
  };
  const debSearch = debounce(onSearch);

  const changeCurrentLang = (lang) => {
    let l = lang === "EN" ? "ru" : "en";
    i18n.changeLanguage(l);
    localStorage.setItem("lang", l);
  };
  const Links: ILinks[] = [
    {
      title: `${t("navbar::vrTour")}`,
      path: "https://mechanicsofwonder.com",
      isOtherLink: true,
    },
    {
      title: `${t("navbar::catalog")}`,
      path: "p/catalog",
    },

    {
      title: `${t("navbar::wallet")}`,
      path: "p/profile/me/wallet",
      isMobile: true,
    },
    {
      title: `${localStorage
        .getItem("lang")
        ?.toUpperCase()
        .replace(/\"/g, "")}`,
      path: null,
      dropdown: [{ title: "Русский" }, { title: "English" }],
    },
    {
      title: `${t("navbar::additionally")}`,
      path: pathname,
      dropdown: pages,
      isGamburger: true,
      iconLink: require("../../../images/gamb.png"),
    },
  ];

  return (
    <Container style={{ display: uiStore.navbar ? "block" : "none" }}>
      <Flex>
        <Logo>
          <NavLink to="/" style={{ textDecoration: "none", color: "white" }}>
            <img
              src={require("./../../../images/wonder-logo.jpg")}
              height="50px"
            />
            {/* <LogoFirstText>{t("navbar::logo-text-1")}</LogoFirstText>
            <LogoSecondText>{t("navbar::logo-text-2")}</LogoSecondText> */}
          </NavLink>
        </Logo>

        <div style={{ height: "35px" }}>
          <SearchInput
            inputRef={searchInputRef}
            onChange={(e) => debSearch(e)}
            id="navbarSearchInput"
          />
        </div>

        <span />

        {Links.filter((item) => !item?.isMobile).map((link, i) => {
          return <NavbarButton link={link} key={i} i={i} />;
        })}

        <AvatarMenu
          onMouseEnter={() => {
            setAvaDropdown(true);
          }}
          onMouseLeave={() => setAvaDropdown(false)}
        >
          {userStore.isAuth ? (
            <img src={Path + "/" + userStore.user?.photo} />
          ) : (
            // <FontAwesomeIcon icon={faBars} />
            <img
              src={require("./../../../images/icons/profile-circle.svg")}
              // style={{ width: "17px", height: "17px", marginLeft: "6px" }}
            />
          )}
          {avaDropdown && (
            <>
              <DropdownMenu
                className="desktop border-grarient"
                style={{
                  right: "126px",
                  top: userStore.isAuth ? "73px" : "73px",
                  position: "absolute",
                }}
              >
                {userStore.isAuth ? (
                  <>
                    <DropdownLink to={"/p/profile/me"}>
                      {t("navbar::profile")}
                    </DropdownLink>
                    <DropdownLink
                      to={"/"}
                      onClick={() => {
                        userStore.LogOut();
                      }}
                    >
                      {t("navbar::logout")}
                    </DropdownLink>
                  </>
                ) : (
                  <DropdownLink to={"/p/auth"}>
                    {t("navbar::login")}
                  </DropdownLink>
                )}
              </DropdownMenu>

              <DropdownMenu
                className="mobile border-grarient"
                style={{
                  right: "20px",
                  top: userStore.isAuth ? "49px" : "45px",
                }}
              >
                {Links.map((link, i) => {
                  if (link.private && !userStore.isAuth) return;
                  if (link?.isGamburger) {
                    return link?.dropdown?.map((dropdown) => {
                      return (
                        <DropdownLink
                          to={
                            dropdown.path
                              ? "/" + dropdown.path
                              : window.location.pathname
                          }
                          key={i}
                        >
                          {dropdown.title}
                        </DropdownLink>
                      );
                    });
                  }
                  if (link.isOtherLink) {
                    return (
                      <DropdownLinkA href={link.path} key={i}>
                        {link.title}
                      </DropdownLinkA>
                    );
                  }
                  return (
                    <DropdownLink
                      to={
                        link.path ? "/" + link.path : window.location.pathname
                      }
                      onClick={() => {
                        !link.path && changeCurrentLang(link.title);
                      }}
                      key={i}
                    >
                      {link.title}
                    </DropdownLink>
                  );
                })}
                {userStore.isAuth ? (
                  <>
                    <DropdownLink to={"/p/profile/me"}>
                      {t("navbar::profile")}
                    </DropdownLink>
                    <DropdownLink
                      to={"/"}
                      onClick={() => {
                        userStore.LogOut();
                      }}
                    >
                      {t("navbar::logout")}
                    </DropdownLink>
                  </>
                ) : (
                  <DropdownLink to={"/p/auth"}>
                    {t("navbar::login")}
                  </DropdownLink>
                )}
              </DropdownMenu>
            </>
          )}
        </AvatarMenu>

        <WalletWrapper>
          <NavLink to={`${match.path}p/profile/me/wallet`}>
            <img
              src={require("./../../../images/icons/clarity_wallet-line.svg")}
              height="32px"
              width="32px"
            />
          </NavLink>
        </WalletWrapper>
      </Flex>
    </Container>
  );
});

export default Navbar;

interface ILinks {
  title?: string;
  path: string;
  dropdown?: any;
  private?: boolean;
  isGamburger?: boolean;
  isOtherLink?: boolean;
  iconLink?: string;
  isMobile?: boolean;
}
