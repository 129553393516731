import "./catalog.sass";
import { url } from "../../components/auth/helper";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useEffect, useState, lazy, Suspense } from "react";
import { useInjection } from "inversify-react";
import { UsersStore } from "../../stores/users/UsersStore";
import Web3 from "web3";
import { ButtonText } from "../../shared/buttons/buttons";
import ReactPlayer from "react-player";
// import Preview from './Preview';
import { ModalsEnum, ModalStore } from "../../stores/ModalStore";
import { useTranslation } from "react-i18next";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faHeart, faHourglassStart, faStar } from '@fortawesome/free-solid-svg-icons';
import { NFTstore } from "../../stores/NFTstore.";
import { UserStore } from "../../stores/user/UserStore";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { showTimerTo } from "./functions";
import Preview from "./Preview";

// const Preview = lazy(() => import("./Preview"));

const Wrapper = styled.div`
  // margin: 2px;
  padding: 20px;
  border-radius: 16px;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.01);
  }
  & #pointer {
    cursor: pointer;
  }
`;

const Container = styled.div`
  & .author {
    cursor: pointer;
    &img {
      display: none;
      width: 24px !important;
      margin: 0;
    }
  }
`;
export interface ILike {
  liked: boolean;
  wished: boolean;
}
const WishLikes = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  z-index: 9;
  & div {
    border-radius: 3px;
    // background-color: #225373;
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
  }
  & .like {
    padding: 0px 5px;
    margin-left: 5px;
    & svg {
      margin-left: 5px;
      & path {
        stroke: white;
        stroke-width: 35px;
        fill: ${(p: ILike) => (p.liked ? "white" : "transparent")};
      }
    }
  }
  & .views {
    padding: 0px 5px;
    margin-left: 5px;
    & svg {
      margin-left: 5px;
      & path {
        /* stroke: white;
    stroke-width: 35px; */
        fill: white;
      }
    }
  }
  & .wish {
    padding: 4px 5px;
    & svg {
      & path {
        stroke: white;
        stroke-width: 35px;
        fill: ${(p: ILike) => (p.wished ? "white" : "transparent")};
      }
    }
  }
`;

const Price = styled.div`
  font-family: Releway-Bold;

  font-weight: 600;
  font-size: 22px;
  color: #fff;
`;

const NFTMediaWrapper = styled.div`
  overflow: hidden;
  border-radius: 15px;
  cursor: pointer;

  &:before {
    z-index: -1;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: center;
  color: #fff;
  font-size: 18px;
  line-height: 1.5em;

  & img {
    width: 57px;
    height: 57px;
    &.sale-icon {
      margin-left: auto;
      cursor: pointer;
    }
  }
`;

const PriceInfo = styled.div`
  margin-left: 15px;
  & p {
    font-size: 16px;
    margin-bottom: 0;
  }
  & span {
    font-weight: 700;
  }
`;

const CatalogItem = observer(
  ({
    el,
    mostExpensive,
    showDelayTokens,
    isProfile = false,
    isCurrentUser = false,
  }: any) => {
    const history = useHistory();
    const usersStore = useInjection(UsersStore);
    const userStore = useInjection(UserStore);
    const nftStore = useInjection(NFTstore);
    const { t } = useTranslation();
    let web3 = new Web3(process.env.RPC_URL_56);

    const [isAuc, setIsAuc] = useState(false);
    const [daysLeft, setDaysLeft] = useState<string>("");
    const [daysToStart, setDaysToStart] = useState<string>("");
    const [liked, setLiked] = useState(false);
    const [likes, setLikes] = useState(0);
    const [wished, setWished] = useState(false);
    const [eng, setEng] = useState(false);

    const modalStore = useInjection(ModalStore);

    useEffect(() => {
      if (el?.isSelling && el?.auction?.open) {
        setIsAuc(true);
      }

      let int = setInterval(() => {
        let toEnd = showTimerTo(el?.auction?.dateEnd, t);
        setDaysLeft(toEnd);
        let toStart = showTimerTo(el?.auction?.startDate, t);
        setDaysToStart(toStart);
      }, 1000);
      if (el?.auction?.open) {
        int;
      }
      return () => {
        clearInterval(int);
      };
    }, [el]);

    useEffect(() => {
      showWishLikes();
    }, [userStore.user, el.likes]);
    useEffect(() => {
      if (localStorage.getItem("lang") === "en") {
        setEng(true);
      } else {
        setEng(false);
      }
    }, [localStorage.getItem("lang")]);
    const showWishLikes = () => {
      if (userStore.user) {
        const l = el.likes?.some((id) => id === userStore.user?._id);
        const w = userStore.user?.wishlist?.some((wish) => wish._id === el._id);
        setLiked(l);
        setWished(w);
      }
      const v = el.likes ? el.likes.length : 0;
      setLikes(v);
    };

    const transl = (category) => {
      if (category === "Изображение") {
        return "Image";
      } else if (category === "Векторное изображение") {
        return "Vector Image";
      } else if (category === "Панорамное изображение") {
        return "Panoramic Image";
      } else if (category === "Видео") {
        return "Video";
      } else if (category === "Панорамное видео") {
        return "Video";
      } else if (category === "3Д Видео") {
        return "3D Video";
      } else if (category === "3Д Модель") {
        return "3D Model";
      } else if (category === "Интерактивный контент") {
        return "Interactive content";
      }
    };

    const getSaleUnsaleValue = (el, isSelleing) => {
      return {
        id: el?._id,
        amount:
          el?.instances?.filter(
            (el) => isSelleing && el?.owner === userStore.user?._id
          )?.length > 1
            ? el?.instances?.filter(
                (el) => isSelleing && el?.owner === userStore.user?._id
              ).length
            : 1,
      };
    };

    const authorClick = (e) => {
      e.stopPropagation();
      usersStore.getOneUser(el.owner.user_id);
      history.push({
        pathname: "/p/profile/artist/" + el.owner.user_id,
        state: { user_id: el.owner.user_id },
      });
    };

    const likeClick = () => {
      if (userStore.isAuth) {
        nftStore.likeNft(el._id);
        const newV = liked === true ? likes - 1 : likes + 1;
        setLiked(!liked);
        setLikes(newV);
      } else toast.error("Необходимо авторизироваться");
    };

    const wishClick = () => {
      if (userStore.isAuth) {
        userStore.addTokenToWishlist(el._id);
        setWished(!wished);
      } else toast.error("Необходимо авторизироваться");
    };

    return (
      <Wrapper className="border-grarient">
        <Container className="ct-contentItem">
          <NFTMediaWrapper className="border-grarient">
            <Suspense fallback={null}>
              <Preview
                type={el?.category}
                history={history}
                el={el}
                most={mostExpensive}
              ></Preview>
            </Suspense>

            <WishLikes liked={liked} wished={wished}>
              <div className="wish" onClick={wishClick}>
                {/* <FontAwesomeIcon icon={faStar}  /> */}
                <img
                  src={
                    wished
                      ? require("../../images/star-bold.png")
                      : require("../../images/star-thin.png")
                  }
                  style={{ width: "42px", height: "42px" }}
                />
              </div>
              <div className="like" onClick={likeClick}>
                {/* <FontAwesomeIcon icon={faHeart}  /> */}
                <img
                  src={
                    liked
                      ? require("../../images/heart-bold.png")
                      : require("../../images/heart.png")
                  }
                  style={{
                    width: "36px",
                    height: "36px",
                  }}
                />
              </div>
              {/* <div className='views' >
                                {el.views}
                                <img src={require('../../images/icons/eye.svg')}style={{width:'21px', height:'17px',filter:'invert(1)'}} className='icon'/>
                            </div> */}
            </WishLikes>
          </NFTMediaWrapper>
          <div className="ct-background">
            <div
              id="pointer"
              className="ct-contentTitle"
              onClick={() => history.push(`/p/nft/${el._id}`)}
            >
              {el?.title}
            </div>

            <div className="ct-contentTags">
              <p>{eng ? transl(el?.category) : el?.category}</p>
            </div>

            <PriceWrapper>
              <img
                src={require("../../images/binance_icon_146221.png")}
                alt="bnb"
              />
              <PriceInfo>
                <p>{`${t("nft::currentPrice")}`}:</p>
                <span>
                  {`${
                    el?.price
                      ? web3.utils.fromWei(el?.price?.toString(), "ether")
                      : "??"
                  } BNB`}
                </span>
              </PriceInfo>
              <img
                onClick={() => history.push(`/p/nft/${el._id}`)}
                className="sale-icon"
                src={require("../../images/carbon_shopping-cart-arrow-down.png")}
                alt="sale"
              />
            </PriceWrapper>
          </div>
        </Container>
      </Wrapper>
    );
  }
);

export default CatalogItem;

{
  /* <Info>
<p>{isAuc?'Ставка':'Цена'} </p>
&nbsp;
<span style={{display:'inline-block' ,maxWidth:'40px', overflow:'hidden', textOverflow:'ellipsis'}}>{!isAuc ? el.price : el.auction?.startPrice}</span>
<span> BNB</span>
</Info> */
}
